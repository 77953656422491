//Technology
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Functions
import { downloadBusinessplanTextDocument, downloadBusinessplanPDFDocument } from "../../functions/DownloadFunctions";
//Components
import AlertModal from "../Modals/AlertModal";
import FailAlert from "../Modals/FailAlert";
import LoadingSpinner from "../Loading/LoadingSpinner";
import Modal from '../Modals/Modal'
import SuccessAlert from "../Modals/SuccessAlert";

export function CustomerList() {
  const axiosPrivate = useAxiosPrivate();

  //api url paths
  const businessplanoverview_URL = "/customerbusinessplanoverview";
  const lockCustomer_URL = "/lockcustomer";
  const unlockcustomer_URL = "/unlockcustomer";
  const lockBusinessplan_URL = "/lockbusinessplan";
  const unlockBusinessplan_URL = "/unlockbusinessplan";
  const deleteBusinessplan_URL = "/deletebusinessplan";
  const deleteCustomer_URL = "/deletecustomer";

  //useStates for data
  const [information, setInformation] = useState([]); //array of custmomers and their businessplans catched in the array 
  const [expandedCustomers, setExpandedCustomers] = useState([]); //handles the table row expand

  //useStates for loading
  const [isLoading, setIsLoading] = useState(true); //handles showing initial loading
  const [getUserSuccessFull, setGetUserSuccessFull] = useState(false); //handles if user fetching was successfull
  const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false); //handles showing currently processing

  //useStates for AlertModals
  const [showBuiDelete, setShowBuiDelete] = useState([]); //delete businessplan and show modal
  const [showCustomLockCustIds, setShowCustomLockCustIds] = useState([]); //lock customers and show modal
  const [showCustomUnlockCustIds, setShowCustomUnlockCustIds] = useState([]); //unlock customers and show modal
  const [showBuiLock, setShowBuiLock] = useState([]); // lock businessplans and show modal
  const [showBuiUnlock, setShowBuiUnlock] = useState([]); // unlock businessplans and show modal
  const [showDelCustomer, setShowDelCustomer] = useState([]); // delete customer and show modal
  const [showVerificationNeeded, setShowVerificationNeeded] = useState([]); // delete customer and show modal
  const [showLockedByWrongInput, setShowLockedByWrongInput] = useState([]); // delete customer and show modal

  //useStates for success alerts
  const [successCustomerLock, setSuccessCustomerLock] = useState(false); //for lock customer
  const [successCustomerUnlock, setSuccessCustomerUnlock] = useState(false); //for unlock customer 
  const [successCustomerDel, setSuccessCustomerDel] = useState(false); //for delete customer
  const [successBuiPlanLock, setSuccessBuiPlanLock] = useState(false); //for lock businessplan
  const [successBuiPlanUnlock, setSuccessBuiPlanUnlock] = useState(false); //for unlock businessplan
  const [successBuiPlanDel, setSuccessBuiPlanDel] = useState(false); //for delete businessplan

  //useStates for all success messages
  const [msgSuccessCustomerDel, setMsgSuccessCustomerDel] = useState(""); //for delete customer
  const [msgSuccessBuiPlanDel, setMsgSuccessBuiPlanDel] = useState(""); //for delete businessplan
  const [msgSuccessBuiPlanLock, setMsgSuccessBuiPlanLock] = useState(""); //for lock businessplan
  const [msgSuccessCustomerLock, setMsgSuccessCustomerLock] = useState(""); //for lock customer
  const [msgSuccessBuiPlanUnlock, setMsgSuccessBuiPlanUnlock] = useState(""); //for unlock businessplan
  const [msgsuccessCustomerUnlock, setMsgSuccessCustomerUnlock] = useState(""); //for unlock customer 

  //useStates for all error messages
  const [errMsg, setErrMsg] = useState(""); //for fetchTable information request
  const [errMsgDelCust, setErrMsgDelCust] = useState(""); //for delete customer
  const [errMsgLockCust, setErrMsgLockCust] = useState();//for  lock customer
  const [errMsgUnlockCust, setErrMsgUnlockCust] = useState();//for  unlock customer
  const [errMsgLockBuiPlan, setErrMsgLockBuiPlan] = useState(""); // for lock  businessplan
  const [errMsgUnlockBuiPlan, setErrMsgUnlockBuiPlan] = useState(""); // for unlock businessplan
  const [errMsgDelBuiPlan, setErrMsgDelBuiPlan] = useState(""); //for delete businessplan
  const [failAlertErrMsg, setFailAlertErrMsg] = useState(""); //for fail alert
  const [showFailAlert, setShowFailAlert] = useState(false); //handles showing of fail alert

  //fetches customer information and their businessplans
  const fetchInformation = async () => {
    await axiosPrivate
      .get(businessplanoverview_URL)
      .then((response) => {
        setInformation(response.data.customers);
        setErrMsg("");
        setGetUserSuccessFull(true);
        setIsLoading(false);
        setIsCurrentlyProcessing(false);
      }).catch((error) => {
        setErrMsg(error.response.data.error);
        setGetUserSuccessFull(false);
        setIsLoading(false);
        setIsCurrentlyProcessing(false);
      });
  };

  // deletes businessplan
  async function deleteBusinessplan(businessplanid) {
    await axiosPrivate({
      method: "DELETE",
      url: deleteBusinessplan_URL,
      data: { businessplanid: businessplanid }
    })
      .then(async (response) => {
        setSuccessBuiPlanDel(true);
        setMsgSuccessBuiPlanDel(response.data.message);
        setErrMsgDelBuiPlan("");
        setShowBuiDelete(showBuiDelete.filter((e) => e !== businessplanid));
        setTimeout(() => { setSuccessBuiPlanDel(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgDelBuiPlan(error.response.data.error);
        setIsCurrentlyProcessing(false);
      })
  }

  //deletes customer
  async function deleteCustomer(customerid) {
    await axiosPrivate({
      method: "DELETE",
      url: deleteCustomer_URL,
      data: { customerid: customerid }
    })
      .then(async (response) => {
        setSuccessCustomerDel(true);
        setMsgSuccessCustomerDel(response.data.message);
        setErrMsgDelCust("");
        setShowDelCustomer(showDelCustomer.filter((e) => e !== customerid));
        setTimeout(() => { setSuccessCustomerDel(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgDelCust(error.response.data.error);
        setIsCurrentlyProcessing(false);
      })
  }

  //locks customer
  async function lockCustomer(custid) {
    await axiosPrivate
      .post(lockCustomer_URL, JSON.stringify({ customerid: custid }))
      .then(async (response) => {
        setSuccessCustomerLock(true);
        setMsgSuccessCustomerLock(response.data.message);
        setErrMsgLockCust("");
        setShowCustomLockCustIds(showCustomLockCustIds.filter((e) => e !== custid));
        setTimeout(() => { setSuccessCustomerLock(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgLockCust(error.response.data.error);
        setIsCurrentlyProcessing(false);
      });
  }

  //unlocks customer
  async function unlockCustomer(custid) {
    await axiosPrivate
      .post(unlockcustomer_URL, JSON.stringify({ customerid: custid }))
      .then(async (response) => {
        setSuccessCustomerUnlock(true);
        setMsgSuccessCustomerUnlock(response.data.message);
        setErrMsgUnlockCust("");
        setShowCustomUnlockCustIds(showCustomUnlockCustIds.filter((e) => e !== custid));
        setTimeout(() => { setSuccessCustomerUnlock(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgUnlockCust(error.response.data.error);
        setIsCurrentlyProcessing(false);
      });
  }

  //locks businessplan
  async function lockBusinessplan(businessplanid) {
    await axiosPrivate
      .post(
        lockBusinessplan_URL,
        JSON.stringify({ businessplanid: businessplanid })
      )
      .then(async (response) => {
        setSuccessBuiPlanLock(true);
        setMsgSuccessBuiPlanLock(response.data.message);
        setErrMsgLockBuiPlan("");
        setShowBuiLock(showBuiLock.filter((e) => e !== businessplanid));
        setTimeout(() => { setSuccessBuiPlanLock(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgLockBuiPlan(error.response.data.error);
        setIsCurrentlyProcessing(false);
      });
  }

  //unlocks businessplan
  async function unlockBusinessplan(businessplanid) {
    await axiosPrivate
      .post(
        unlockBusinessplan_URL,
        JSON.stringify({ businessplanid: businessplanid }))
      .then(async (response) => {
        setSuccessBuiPlanUnlock(true);
        setMsgSuccessBuiPlanUnlock(response.data.message);
        setErrMsgUnlockBuiPlan("");
        setShowBuiUnlock(showBuiUnlock.filter((e) => e !== businessplanid));
        setTimeout(() => { setSuccessBuiPlanUnlock(false) }, 3000);
        await fetchInformation();
      }).catch((error) => {
        setErrMsgUnlockBuiPlan(error.response.data.error);
        setIsCurrentlyProcessing(false);
      });
  }

  //function to lock/unlock customer
  async function toggleCustomerStatus(custid, status) {
    if (status === "active") {
      setIsCurrentlyProcessing(true);
      await lockCustomer(custid);
    } else if (status === "verificationNeeded" || status === "lockedByWrongInput") {
      setShowCustomUnlockCustIds(showCustomUnlockCustIds.filter((e) => e !== custid));
    } else {
      setIsCurrentlyProcessing(true);
      await unlockCustomer(custid);
    }
  }

  //function to lock/unlock businessplan
  async function toggleBusinessplanLocked(businessplanid, locked) {
    setIsCurrentlyProcessing(true);
    if (locked === true) {
      await unlockBusinessplan(businessplanid);
      await fetchInformation();
    } else {
      await lockBusinessplan(businessplanid);
      await fetchInformation();
    }
  }

  //function to delete businesspln
  async function toggleBusinessplanDeleted(businessplanid) {
    setIsCurrentlyProcessing(true);
    await deleteBusinessplan(businessplanid);
  }

  //function to delete customer
  async function toggleCustomerDeleted(customerid) {
    setIsCurrentlyProcessing(true);
    await deleteCustomer(customerid);
  }

  //onclose functions for modals 
  async function onCloseDelCustomer(customerid) {
    setShowDelCustomer(showDelCustomer.filter((e) => e !== customerid));
    setErrMsgDelCust("");
    setMsgSuccessCustomerDel("");
  }

  async function onCloseLockCustomer(custid) {
    setShowCustomLockCustIds(showCustomLockCustIds.filter((e) => e !== custid));
    setErrMsgLockCust("");
    setMsgSuccessCustomerLock("");
  }

  async function onCloseUnlockCustomer(custid) {
    setShowCustomUnlockCustIds(showCustomUnlockCustIds.filter((e) => e !== custid));
    setErrMsgUnlockCust("");
    setMsgSuccessCustomerUnlock("");
  }

  async function onCloseLockBuiPlan(businessplanid) {
    setShowBuiLock(showBuiLock.filter((e) => e !== businessplanid));
    setErrMsgLockBuiPlan("");
    setMsgSuccessBuiPlanLock("");
  }

  async function onCloseUnlockBuiPlan(businessplanid) {
    setShowBuiUnlock(showBuiUnlock.filter((e) => e !== businessplanid));
    setErrMsgUnlockBuiPlan("");
    setMsgSuccessBuiPlanUnlock("");
  }

  async function onCloseDelBuiPlan(businessplanid) {
    setShowBuiDelete(showBuiDelete.filter((e) => e !== businessplanid));
    setErrMsgDelBuiPlan("");
    setMsgSuccessBuiPlanDel("");
  }

  //function to render current status of customer
  const getStatus = (status) => {
    if (status === "active") {
      return <td className="tdForStatusActive">Aktiv</td>;
    } else if (status === "lockedByCompany") {
      return <td className="tdForStatusLocked">Gesperrt</td>;
    } else if (status === "lockedByWrongInput") {
      return <td className="tdForStatusWrongInput">Sperrung durch Falscheingabe</td>;
    } else if (status === "verificationNeeded") {
      return <td className="tdForStatusVerificationNeeded">Verifizierung ausstehend</td>;
    }
  };

  async function handleDownloadBusinessplanTextDocument(bpid) {
    setIsCurrentlyProcessing(true);
    try {
      await downloadBusinessplanTextDocument(bpid, axiosPrivate);
    } catch (error) {
      setAndDisplayFailAlertErrorMessage(error.message);
    }
    setIsCurrentlyProcessing(false);
  }

  async function handleDownloadBusinessplanPDFDocument(bpid) {
    setIsCurrentlyProcessing(true);
    try {
      await downloadBusinessplanPDFDocument(bpid, axiosPrivate);
    } catch (error) {
      setAndDisplayFailAlertErrorMessage(error.message);
    }
    setIsCurrentlyProcessing(false);
  }

  const setAndDisplayFailAlertErrorMessage = (errorMsg) => {
    setShowFailAlert(true);
    setFailAlertErrMsg(errorMsg);
    setTimeout(() => { setShowFailAlert(false); setFailAlertErrMsg(""); }, 3000);
  }

  const TableRow = ({ row, expanCell, rowStyle }) => {
    return (
      <>
        <tr className="trCustomeroverview">
          <td className={expandedCustomers.includes(row.email) ? "btnToggleExpandActive" : "btnToggleExpand"}>
            {expanCell}
          </td>
          {getStatus(row.status)}
          <td>{row.secondName}</td>
          <td>{row.firstName}</td>
          <td>{row.email}</td>
          <td>{row.firstLogin}</td>
          <td className="lastLoginColumnCustomeroverview">{row.lastLogin}</td>
          <td></td>
          <td></td>
          <td className="tdForIcons">
            <div onClick={() => {
              (row.status === "active")
                ? setShowCustomLockCustIds([...showCustomLockCustIds, row.customerid,])
                : (row.status === "verificationNeeded")
                  ? setShowVerificationNeeded([...showVerificationNeeded, row.customerid,])
                  : row.status === "lockedByCompany"
                    ? setShowCustomUnlockCustIds([...showCustomUnlockCustIds, row.customerid])
                    : setShowLockedByWrongInput([...showLockedByWrongInput, row.customerid]);
            }}
              className="tdLockCustomer"
            ></div>
            <AlertModal
              title="Konto sperren?"
              name="Ja, Konto sperren"
              button="Abbrechen"
              onClose={() => onCloseLockCustomer(row.customerid)}
              show={showCustomLockCustIds.includes(row.customerid)}
              onSubmit={() => toggleCustomerStatus(row.customerid, row.status)}
            >
              <p>Du bist dabei das Konto{" "} <b>{row.email}</b>&nbsp;zu sperren.<br></br>Bis zur Entsperrung kann nicht auf dieses Konto zugegriffen werden.
              </p>
              <p className={errMsgLockCust ? "errmsgCustomerOverview" : "offscreen"}>{errMsgLockCust}</p>
            </AlertModal>
            <Modal
              title="Konto entsperren?"
              name="Ja, Konto entsperren"
              button="Abbrechen"
              onClose={() => onCloseUnlockCustomer(row.customerid)}
              show={showCustomUnlockCustIds.includes(row.customerid)}
              onSubmit={() => toggleCustomerStatus(row.customerid, row.status)}>
              <p>Du bist dabei das Konto{" "} <b>{row.email}</b>
                &nbsp;zu entsperren.<br></br>Bist du dir sicher,
                dass du das Konto entsperren möchtest?
              </p>
              <p className={errMsgUnlockCust ? "errmsgCustomerOverview" : "offscreen"}>{errMsgUnlockCust}</p>
            </Modal>
            <AlertModal
              title="Gesperrt durch Falscheingaben"
              button="Abbrechen"
              name="OK"
              onClose={() => setShowLockedByWrongInput(showLockedByWrongInput.filter((e) => e !== row.customerid))}
              show={showLockedByWrongInput.includes(row.customerid)}
              onSubmit={() => setShowLockedByWrongInput(showLockedByWrongInput.filter((e) => e !== row.customerid))}>
              <p>Das Konto <b>{row.email} </b>
                ist gesperrt durch Falscheingaben.
                Du kannst den Status des Kontos nicht ändern.
              </p>
            </AlertModal>
            <AlertModal
              title="Verifizierung ausstehend"
              name="OK"
              button="Abbrechen"
              onClose={() => setShowVerificationNeeded(showVerificationNeeded.filter((e) => e !== row.customerid))}
              show={showVerificationNeeded.includes(row.customerid)}
              onSubmit={() => setShowVerificationNeeded(showVerificationNeeded.filter((e) => e !== row.customerid))}>
              <p>Die Verifizierung des Kontos
                <b> {row.email} </b>
                steht noch aus.
                Deshalb kann es nicht gesperrt oder entsperrt werden.
              </p>
            </AlertModal>
          </td>
          <td className="tdForIcons">
            <div className="btnDeleteCustomer" onClick={() => setShowDelCustomer([...showDelCustomer, row.customerid])}>
            </div>
            <AlertModal
              title="Konto löschen?"
              name="Ja, Konto löschen"
              button="Abbrechen"
              onClose={() => onCloseDelCustomer(row.customerid)}
              show={showDelCustomer.includes(row.customerid)}
              onSubmit={() => toggleCustomerDeleted(row.customerid)}>
              <p> Du bist dabei das Konto{" "} <b>{row.email}</b>&nbsp;zu löschen.
                <br></br>Bist du dir sicher, dass du das Konto löschen möchtest?
              </p>
              <p className={errMsgDelCust ? "errmsgCustomerOverview" : "offscreen"}>{errMsgDelCust}</p>
            </AlertModal>
          </td>
        </tr>
        {row.businessplans.length !== 0 && (
          <tr style={rowStyle} className="trSubRowsHeadingCustomeroverview">
            <td></td>
            <td></td>
            <td colSpan={3}> <p className="textSubRowHeadingTitel">Titel</p></td>
            <td colSpan={6}><p className="textSubRowHeading">Letzte Bearbeitung</p></td>
          </tr>
        )}
        {row.businessplans
          .sort((a, b) => {
            return a.title.toUpperCase() === b.title.toUpperCase()
              ? 0
              : a.title.toUpperCase() < b.title.toUpperCase()
                ? -1
                : 1;
          }).map((elem, index) => {
            return (
              <tr
                style={rowStyle}
                className="trSubRowsCustomeroverview"
                key={index} //no purpose but cleaning the console from an error
              >
                <td></td>
                <td className="tdForIcons">
                  {/* UN/LOCK BUSINESSPLAN */}
                  <div onClick={() => {
                    elem.locked === false
                      ? setShowBuiLock([...showBuiLock, elem.businessplanid])
                      : setShowBuiUnlock([...showBuiUnlock, elem.businessplanid]);
                  }}
                    className={elem.locked === false ? "activeLock" : "inactiveLock"}
                  ></div>
                  <AlertModal
                    show={showBuiLock.includes(elem.businessplanid)}
                    onClose={() => onCloseLockBuiPlan(elem.businessplanid)}
                    title="Businessplan sperren?"
                    name="Ja, Plan sperren"
                    button="Abbrechen"
                    onSubmit={() => toggleBusinessplanLocked(elem.businessplanid, elem.locked)}>
                    <p>Du bist dabei den Businessplan{" "} <b>{elem.title}</b>
                      {" "} des Kontos
                      <b> {row.email} </b>zu
                      sperren.
                      <br></br><br></br>
                      Dieses Konto wird bis zur Entsperrung keinen Zugriff auf diesen Businessplan haben.
                    </p>
                    <p className={errMsgLockBuiPlan ? "errmsgCustomerOverview" : "offscreen"}>{errMsgLockBuiPlan}</p>
                  </AlertModal>
                  <Modal
                    show={showBuiUnlock.includes(elem.businessplanid)}
                    onClose={() => onCloseUnlockBuiPlan(elem.businessplanid)}
                    title="Businessplan entsperren?"
                    name="Ja, Plan entsperren"
                    button="Abbrechen"
                    onSubmit={() => toggleBusinessplanLocked(elem.businessplanid, elem.locked)}>
                    <p>Du bist dabei den Businessplan{" "} <b>{elem.title} </b>
                      des Kontos
                      <br></br>
                      <b> {row.email} </b> zu entsperren.
                      <br></br>
                      <br></br>
                      Bist du dir sicher, dass du den Businessplan entsperren möchtest?
                    </p>
                    <p className={errMsgUnlockBuiPlan ? "errmsgCustomerOverview" : "offscreen"}>{errMsgUnlockBuiPlan}</p>
                  </Modal>
                </td>
                <td colSpan={3} className="tdBusinessplanTitle">{elem.title}</td>
                <td colSpan={3} className="tdBusinessplanLastEdit"> <p className="pBusinessplanEdit">{elem.lastEdit}</p></td>
                <td className="tdForIcons">
                  <div className="tdWordButton" onClick={() => handleDownloadBusinessplanTextDocument(elem.businessplanid)}></div>
                </td>
                <td className="tdForIcons">
                  <div className="tdPdfButton" onClick={() => handleDownloadBusinessplanPDFDocument(elem.businessplanid)}></div>
                </td>
                <td className="tdForIconsDeleteBusinessplan">
                  {/* DELETE BUSINESSPLAN */}
                  <div className="tdDeleteBuiPlanButton"
                    onClick={() => {
                      setShowBuiDelete([...showBuiDelete, elem.businessplanid]);
                    }}
                  ></div>
                  <AlertModal
                    show={showBuiDelete.includes(elem.businessplanid)}
                    title="Businessplan löschen? "
                    name="Businessplan löschen"
                    button="Abbrechen"
                    onClose={() => onCloseDelBuiPlan(elem.businessplanid)}
                    onSubmit={() => toggleBusinessplanDeleted(elem.businessplanid)}>
                    <p>Du bist dabei den Businessplan<b> {elem.title} </b>
                      des Kontos
                      <br></br>
                      <b>{row.email} </b>zu löschen.
                      <br></br>
                      <br></br>
                      Alle Daten werden unwiderruflich aus der Datenbank entfernt.
                    </p>
                    <p className={errMsgDelBuiPlan ? "errmsgCustomerOverview" : "offscreen"}>{errMsgDelBuiPlan}</p>
                  </AlertModal>
                </td>

              </tr>
            );
          })
        }
      </>
    );
  };

  //function that expands columns
  const toggleRow = (customerEmail) => {
    setExpandedCustomers((expandedCustomers) => {
      if (expandedCustomers.includes(customerEmail)) {
        return expandedCustomers.filter((c) => c !== customerEmail);
      } else {
        return [...expandedCustomers, customerEmail];
      }
    });
  };

  useEffect(() => {
    fetchInformation();
  }, []);

  return (
    <div className="divForTableCustomeroverview">
      {
        isLoading &&
        <div className="loadingSpinnerDivCustomerOverview">
          <LoadingSpinner />
        </div>
      }
      {
        !isLoading && getUserSuccessFull &&
        <table className="tableCustomeroverview">
          <thead className="theadCustomeroverview">
            <tr className="trHeadingCustomeroverview">
              <th className="thAccordionButton"></th>
              <th className="thStatus">Status</th>
              <th className="thSecondName">Nachname</th>
              <th className="thFirstName">Vorname</th>
              <th className="thEmail">E-Mail-Adresse</th>
              <th className="thFirstLogin">Erster Login</th>
              <th className="thLastLogin">Letzter Login</th>
            </tr>
          </thead>
          <tbody>
            {information
              .map((elem, index) => {
                return (
                  <TableRow
                    rowStyle={{
                      display: expandedCustomers.includes(elem.email)
                        ? "table-row"
                        : "none",
                    }}
                    key={`${index}`}
                    row={elem}
                    expanCell={
                      <button
                        className="buttonForToggleTransparent"
                        onClick={() => toggleRow(elem.email)}
                      ></button>
                    }
                  />
                );
              })}
          </tbody>
        </table>
      }
      <div>
        <SuccessAlert show={successCustomerLock} onClose={() => setSuccessCustomerLock(false)}><p className="rspMessageSuccess">{msgSuccessCustomerLock}</p></SuccessAlert>
        <SuccessAlert show={successCustomerUnlock} onClose={() => setSuccessCustomerUnlock(false)}>{<p className="rspMessageSuccess">{msgsuccessCustomerUnlock}</p>}</SuccessAlert>
        <SuccessAlert show={successCustomerDel} onClose={() => setSuccessCustomerDel(false)}>{<p className="rspMessageSuccess">{msgSuccessCustomerDel}</p>}</SuccessAlert>
        <SuccessAlert show={successBuiPlanLock} onClose={() => setSuccessBuiPlanLock(false)}>{<p className="rspMessageSuccess">{msgSuccessBuiPlanLock}</p>}</SuccessAlert>
        <SuccessAlert show={successBuiPlanUnlock} onClose={() => setSuccessBuiPlanUnlock(false)}>{<p className="rspMessageSuccess">{msgSuccessBuiPlanUnlock}</p>}</SuccessAlert>
        <SuccessAlert show={successBuiPlanDel} onClose={() => setSuccessBuiPlanDel(false)}>{<p className="rspMessageSuccess">{msgSuccessBuiPlanDel}</p>}</SuccessAlert>
        <FailAlert show={showFailAlert} onClose={() => { setShowFailAlert(false); setFailAlertErrMsg("") }}>{<p className="rspMessageError">{failAlertErrMsg}</p>}</FailAlert>
      </div>
      {
        !isLoading && isCurrentlyProcessing &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
      {
        !isLoading && !getUserSuccessFull &&
        <div className="loadingSpinnerDivCustomerOverview">
          <p className={errMsg ? "errmsgCustomerOverview " : "offscreen"} > {errMsg}</p>
        </div>
      }
    </div >
  );
}