import React from "react";

/* Displays a notification for the user when a taken action was successfull. */

const SuccessAlert = props => {
  if (!props.show) {
    return null
  }

  return (
    <div className="success-container" role="alert">
      <div className="success-body">
        <div className="success_check_icon" alt="success"></div>
        <div className="success-content">{props.children}</div>
        <div className="success-close-btn" onClick={props.onClose}></div>
      </div>
    </div>
  )
}

export default SuccessAlert