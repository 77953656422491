//Technology
import React, { useState } from 'react';
import { Link } from "react-router-dom";
//Assets
import imageApp from "../../assets/Auth/Wireframe_Mockup_Login_Registrierung.svg";
import Logo_B3_Business_Beratung from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Components
import FailAlert from '../../components/Modals/FailAlert';
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";

function SignupSuccess() {
  //useStates
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);

  async function handleDownloadTac() {
    try {
      await downloadTaC();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    try {
      await downloadPrivacyStatements();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  function getLinkPart() {
    return (
      <>
        <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
        <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
        <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
      </>
    )
  }

  return (
    <div className="parent signup-success-container" id='signUpPage'>
      <div className="containerLeft">
        <div className="containerForHeadingAndParagraph">
          <h1 className="headingOne">Erstelle deinen Businessplan!</h1>
          <p className="paragraphOne">
            Zu jeder Unternehmensgründung gehört auch ein Businessplan.
            <br /> Bei uns hast du die Möglichkeit einfach aber effektiv deine Gründungsidee zu verwirklichen!
          </p>
        </div>

        <img src={imageApp} className="imageOfApp" alt="" />

        <div className="containerAgbDatenschutzsSuccess">
          {getLinkPart()}
        </div>
      </div>
      <div className="containerForSignupSuccess">
        <div className="containerRightSuccess">
          <img className='logoB3SignUpSuccess' src={Logo_B3_Business_Beratung} alt="" />
          <p className="headingRegistrierungSignupSuccess">Vielen Dank!</p>
          <p className='paragraphSignUpSuccess'>
            Ein Bestätigungslink wurde an deine E-Mail-Adresse <br />
            geschickt.
            <br></br>
            <br></br>
            Du musst dein Konto erst verifizieren, bevor du unser<br></br>
            Tool verwenden kannst. Ist dies erledigt, kannst du dich <br></br>
            einloggen.
          </p>
          <div className="form-group col ">
            <p className="registerHere">Zurück zur <Link to="/customerlogin" className="hoverLink">Anmeldung</Link></p>
            <div className="signupsuccessLink">
              {getLinkPart()}
            </div>
          </div>
        </div>
      </div>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
    </div>
  );
}

export default SignupSuccess;
