import axios from "../api/axios";
var fileDownload = require('js-file-download');

const CONDITIONS_URL = "/validtermsandconditions";
const PRAVACY_URL = "/validprivacystatement";
const DOWNLOADBUSINESSPLAN_WORD = "/businessplanword";
const DOWNLOADBUSINESSPLAN_PDF = "/businessplanpdf";

const labelDownloadFail = "Download fehlgeschlagen.";

export async function downloadTaC() {
  await axios.get(CONDITIONS_URL, {
    responseType: 'blob'
  })
    .then((response) => {
      fileDownload(response.data, response.headers['x-file-name']);
    })
    .catch((err) => {
      throw new Error(labelDownloadFail);
    });
}

export async function downloadPrivacyStatements() {
  await axios.get(PRAVACY_URL, {
    responseType: 'blob'
  })
    .then((response) => {
      fileDownload(response.data, response.headers['x-file-name']);
    })
    .catch((error) => {
      throw new Error(labelDownloadFail);
    });
}

export async function downloadBusinessplanTextDocument(businessplanid, axiosPrivate) {
  await axiosPrivate.get(DOWNLOADBUSINESSPLAN_WORD, {
    params: {
      businessplanid
    },
    responseType: 'blob'
  }).then((response) => {
    fileDownload(response.data, response.headers['x-file-name']);
  }).catch((error) => {
    throw new Error(labelDownloadFail);
  })
}

export async function downloadBusinessplanPDFDocument(businessplanid, axiosPrivate) {
  await axiosPrivate.get(DOWNLOADBUSINESSPLAN_PDF, {
    params: {
      businessplanid
    },
    responseType: 'blob'
  })
    .then((response) => {
      fileDownload(response.data, response.headers['x-file-name']);
    })
    .catch((error) => {
      throw new Error(labelDownloadFail);
    });
}