//Technology
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
//Auth
import useAuth from "../../hooks/useAuth";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
//Components
import FailAlert from "../Modals/FailAlert";

const Navbar = (props) => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  //useStates  
  const [visible, setVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);

  const LOGOUT_URL = "/logout";
  const logout = async () => {
    await axios
      .get(LOGOUT_URL, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
      })
      .then((response) => {
        setAuth({});
        navigate(props.isEmployeeNavbar ? "/employeelogin" : "/customerlogin");
      }).catch((error) => {
        setErrorMessage(error.response.data.error);
        setFailAlertVisible(true);
        setTimeout(() => { setFailAlertVisible(false); setErrorMessage(""); }, 3000)
      });
  }

  async function handleDownloadTac() {
    try {
      await downloadTaC();
    } catch (error) {
      setErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    try {
      await downloadPrivacyStatements();
    } catch (error) {
      setErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setErrorMessage(""); }, 3000)
    }
  }

  return (
    <div className="Navbar">
      <div className={props.whiteNavbar === true ? "NBProfilbildWhite" : "NBProfilbild"} id="pb" onClick={() => setVisible(!visible)}></div>
      {visible && (
        <div className="NBProfilContext" id="ProfilPopUp">
          <div className="NBItem" id="NBIKontoeinstellung" onClick={() => { navigate(props.isEmployeeNavbar ? "/employee/accountsettings" : "/customer/accountsettings") }} >
            <p className="NBItemPTag">Kontoeinstellungen</p>
          </div>
          <div className="NBItem" id="NBIAGB" onClick={async () => await handleDownloadTac()} >
            <p className="NBItemPTag">AGB</p>
          </div>
          <div className="NBItem" id="NBIDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>
            <p className="NBItemPTag">Datenschutz</p>
          </div>
          <div className="NBItem" id="NBIImpressum" onClick={openLegalDisclosures} >
            <p className="NBItemPTag">Impressum</p>
          </div>
          <div className="NBItem" id="NBILogout" onClick={logout}>
            <p className="NBItemPTag">Logout</p>
          </div>
        </div>
      )}
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setErrorMessage(""); }}>{<p className="rspMessageError">{errorMessage}</p>}</FailAlert>
    </div>
  );
};

export default Navbar;