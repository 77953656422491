//Technology
import React, { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
//Assets
import imageApp from "../../assets/Auth/Wireframe_Mockup_Login_Registrierung.svg";
import Logo_B3_Business_Beratung from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
//Components
import FailAlert from '../../components/Modals/FailAlert';
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

function Signup() {
    const errRef = useRef();
    const navigate = useNavigate();

    //useStates
    const [Anrede, setAnrede] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [AGB, setAGB] = useState(false);
    const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
    const [failAlertVisible, setFailAlertVisible] = useState(false);
    const [isSignupLoading, setIsSignupLoading] = useState(false);

    const handleInputChange = (e) => {
        setErrMsg("");
        const { id, value } = e.target;
        if (id === "Anrede") {
            setAnrede(value);
        }
        if (id === "firstName") {
            setFirstName(value);
        }
        if (id === "lastName") {
            setLastName(value);
        }
        if (id === "email") {
            setEmail(value);
        }
        if (id === "password") {
            setPassword(value);
        }
        if (id === "confirmPassword") {
            setConfirmPassword(value);
        }
        if (id === "AGB") {
            setAGB(e.target.checked);
        }
    }

    async function handleDownloadTac() {
        try {
            await downloadTaC();
        } catch (error) {
            setFailAlertErrorMessage(error.message);
            setFailAlertVisible(true);
            setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
        }
    }

    async function handleDownloadPrivacyStatement() {
        try {
            await downloadPrivacyStatements();
        } catch (error) {
            setFailAlertErrorMessage(error.message)
            setFailAlertVisible(true);
            setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
        }
    }

    const handleSubmit = async (e) => {
        setIsSignupLoading(true);
        try {
            e.preventDefault();
            e.stopPropagation();
            await axios.post('/register', JSON.stringify({
                "email": email,
                "firstName": firstName,
                "secondName": lastName,
                "salutation": Anrede,
                "password": password,
                "tacAndPsAccepted": AGB
            }),
                {
                    headers: { "Content-Type": "application/json" }
                }
            );
            setPassword("");
            setConfirmPassword("");
            navigate('/SignupSuccess');
        } catch (error) {
            setErrMsg(error?.response?.data?.error);
            setIsSignupLoading(false);
            errRef.current.focus();
        }
    }

    function getLinkPart() {
        return (
            <>
                <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
                <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
                <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
            </>
        )
    }

    return (
        <div className="parent">
            <div className="containerLeft">
                <div className="containerForHeadingAndParagraph">
                    <h1 className="headingOne">Erstelle deinen Businessplan!</h1>
                    <p className="paragraphOne">
                        Zu jeder Unternehmensgründung gehört auch ein Businessplan.
                        <br /> Bei uns hast du die Möglichkeit einfach aber effektiv deine Gründungsidee zu verwirklichen!
                    </p>
                </div>
                <img src={imageApp} className="imageOfApp" alt="" />
                <div className="containerForParagraphs">
                    {getLinkPart()}
                </div>
            </div>
            <div className="containerForSignup">
                <form className="containerRight needs-validation " noValidate>
                    <img className='logoB3Signup' src={Logo_B3_Business_Beratung} alt="" />
                    <h1 className="headingRegistrierung">Registrierung</h1>
                    <div className='container-for-centering'>
                        {/* container-foor-centering has no css styles but makes sure that the layoput of inputs is correct */}
                        <div className="row">
                            <label htmlFor="Anrede"> Anrede </label>
                        </div>
                        <select className="custom-select" value={Anrede} onChange={(e) => handleInputChange(e)} id="Anrede" required>
                            <option ></option>
                            <option value="Herr">Herr</option>
                            <option value="Frau">Frau</option>
                            <option value="Divers">Divers</option>
                        </select>
                        <div className="row">
                            {/*firstname */}
                            <div className="columnAuto">
                                <label className="" htmlFor="firstName"> Vorname </label>
                                <input className="form-control form-control2" type="text" value={firstName} onChange={(e) => handleInputChange(e)} id="firstName" placeholder="Max" required></input>
                            </div>
                            {/*lastname */}
                            <div className="columnAuto">
                                <label className="" htmlFor="lastName" id="lastNameLbl"> Nachname </label>
                                <input type="text" name="" id="lastName" value={lastName} className="form-control form-control2" onChange={(e) => handleInputChange(e)} placeholder="Mustermann" required></input>
                            </div>
                        </div >
                        {/*email */}
                        <div className="row">
                            <label htmlFor="email">E-Mail-Adresse </label>
                            <div className="">
                                <input type="email" id="email" className="form-control" value={email} onChange={(e) => handleInputChange(e)} placeholder="name@example.de" required />
                            </div>
                        </div>

                        {/* password */}
                        <div className="row">
                            <label htmlFor="password">Passwort</label>
                            <div className="">
                                <input className="form-control" type="password" id="password" value={password} onChange={(e) => handleInputChange(e)} placeholder="Passwort" required />
                            </div>
                        </div>

                        {/* confirmPassword */}
                        <div className="row">
                            <label htmlFor="confirmPassword">Passwort wiederholen</label>
                            <div className="">
                                <input className="form-control" type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => handleInputChange(e)} placeholder="Passwort wiederholen" required />
                            </div >
                        </div>

                        {/*Checkbox*/}
                        <div className="custom-checkbox form-check">
                            <input type="checkbox" className="form-checkbox form-check-input" onChange={(e) => handleInputChange(e)} id="AGB" value={AGB} required />
                            <p className="area-checkbox form-check-label" > &nbsp; Die <strong className="SUDownloadLink" onClick={async () => await downloadPrivacyStatements()}>Datenschutzbestimmung</strong> und <strong className="SUDownloadLink" onClick={async () => await downloadTaC()}>AGB</strong> <br /> &nbsp; habe ich zur Kenntnis  genommen </p>
                        </div>

                        {/*message */}
                        <div className='container-meldung'>
                            {password !== confirmPassword ? <p> <small className="meldung"> Passwörter stimmen nicht überein </small></p> : null}
                        </div>

                        {/* error message */}
                        <p ref={errRef} className={errMsg ? "SUerrmsg" : "offscreen"}>
                            {errMsg}
                        </p>

                        <div className="container-btn-paragraph-signup">
                            <button type="submit" className="registrieren" onClick={handleSubmit} disabled={(confirmPassword === password) ? false : true}>{isSignupLoading ? <LoadingSpinner /> : "Registrieren"}</button>
                            <p className="loginParagLink">
                                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;Du hast schon ein Konto? Hier <Link to="/customerlogin" className="SULoginLink">Anmelden</Link>
                            </p>
                        </div>
                    </div>
                    <div className="signUpLinkPartResponsive">
                        {getLinkPart()}
                    </div>
                </form>
            </div>
            <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
        </div>
    );
}

export default Signup;