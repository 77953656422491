import React from 'react';
import PropTypes from 'prop-types';

const DropFileInput = props => {
    return (
        <div className="drop-file-input">
            <div className="drop-file-input__label">
                <button className="modal-close-btn">Datei auswählen</button>
                <p className='paragraphDropFile'>oder Datei hier ablegen</p>
                {props.filename && <p className='paragraphDropFile'>Ausgewählte Datei: <b>{props.filename}</b></p>}
            </div>
            <input type="file" value="" onChange={(e) => { props.onFileChange(e.target.files[0]) }} />
        </div>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func
}

export default DropFileInput;