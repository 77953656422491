//Technology
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Navbar from "../../components/Navigation/Navbar";
import SuccessAlert from "../../components/Modals/SuccessAlert";
//fileDownload
var fileDownload = require('js-file-download');

const BusinessplanEditing = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const param = useParams();
  const businessplanid = param.businessplanid;
  const navigate = useNavigate();

  //api url paths
  const SUBCHAPTERCONTENT = "/subchaptercontent";
  const QUESTIONANSWER_URL = "/updatequestion";
  const DOWNLOADTABLE_URL = "/downloadtabletemplate";
  const UPDATETABLE_URL = "/updatetable";

  //useStates
  const [currentChapter, setCurrentChapter] = useState();
  const [chapters = [], setChapters] = useState();
  const [subchapters = [], setSubchapters] = useState();
  const [content = [], setContent] = useState();
  const [chapterTitle, setChapterTitle] = useState();
  const [subchapterTitle, setSubchapterTitle] = useState();
  const [currentSubchapter, setCurrentSubchapter] = useState();
  const [expandedChapters, setExpandedChapters] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [popupMessage, setPopUpMessage] = useState("");
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSubchapterLoading, setsIsSubchapterLoading] = useState(false);
  const [navigationLoadedSuccessfully, setNavigationLoadedSuccessfully] = useState(false);
  const [subchapterLoadedSuccessfully, setSubchapterLoadedSuccessfully] = useState(false);
  const [isCurrentlySaving, setIsCurrentlySaving] = useState(false);

  const [elementsChanged, setElementsChanged] = useState([]);
  const [elementsShowTerminology, setElementsShowTerminology] = useState([]);

  useEffect(() => {
    getPartChapters();
  }, []);

  useEffect(() => {
    getNextSubchapter(subchapters);
  }, [subchapters]);

  /**
   * Get Chapters and save them in an array
   */
  async function getPartChapters() {
    if (businessplanid) {
      await axiosPrivate.get(props.chaptersroute, {
        params: {
          businessplanid
        }
      })
        .then((response) => {
          const chaps = response?.data.chapterstructure;
          if (chaps) {
            setChapters(chaps);
            setSubchapters(getSubchapters(chaps));
            setNavigationLoadedSuccessfully(true);
          }
        })
        .catch((error) => {
          setIsInitialLoading(false);
          handleShowFail(error.response.data.error, true);
        });
    } else {
      setIsInitialLoading(false);
      handleShowFail('Die Businessplan-Id muss angegeben werden.', true);
    }
  }

  /**
   * Get subchapters of an chapter and save them in an array
   * @param {*} chapters Object with subchapterchilds
   * @returns array with all subchapters of a spezific chapter
   */
  function getSubchapters(chapters) {
    const result = [];
    chapters.forEach(chapter => {
      chapter.subchapters.forEach(subchapter => {
        result.push({
          subchapterid: subchapter.subchapterid,
          subchaptertitle: subchapter.subchaptertitle,
          chapterid: chapter.chapterid,
          chaptertitle: chapter.chaptertitle
        });
      })
    });
    return result;
  }

  /**
   * function to expand the targeted chapter 
   * @param {*} chapterid 
   */
  function toggleChapterExpanded(chapterid) {
    setExpandedChapters((expandedChapters) => {
      if (expandedChapters.includes(chapterid)) {
        return expandedChapters.filter((c) => c !== chapterid);
      } else {
        return [...expandedChapters, chapterid];
      }
    });
  }

  // function for next and back buttons
  async function getNextSubchapter() {
    let result;
    if (Array.isArray(subchapters) && subchapters.length) {
      const indexarr = [];
      subchapters.forEach(element => {
        indexarr.push(element.subchapterid);
      });
      let nextIndex = 0;
      const found = indexarr.indexOf(currentSubchapter);
      if (found < subchapters.length - 1) {
        nextIndex = found + 1;
      }
      result = { chapterid: subchapters[nextIndex].chapterid, chaptertitle: subchapters[nextIndex].chaptertitle, subchapterid: subchapters[nextIndex].subchapterid, subchaptertitle: subchapters[nextIndex].subchaptertitle };
    }
    if (result) {
      await handleDifferentSubchapter(result.chapterid, result.chaptertitle, result.subchapterid, result.subchaptertitle);
    }
  }

  async function getPrevSubchapter() {
    let result;
    if (Array.isArray(subchapters) && subchapters.length) {
      let indexarr = [];
      subchapters.forEach(element => {
        indexarr.push(element.subchapterid);
      });
      let nextIndex = 0;
      const found = indexarr.indexOf(currentSubchapter);
      if (found > 0) {
        nextIndex = found - 1;
      }
      result = { chapterid: subchapters[nextIndex].chapterid, chaptertitle: subchapters[nextIndex].chaptertitle, subchapterid: subchapters[nextIndex].subchapterid, subchaptertitle: subchapters[nextIndex].subchaptertitle };
    }
    if (result) {
      await handleDifferentSubchapter(result.chapterid, result.chaptertitle, result.subchapterid, result.subchaptertitle);
    }
  }

  async function handleDifferentSubchapter(chapterid, chaptertitle, subchapterid, subchaptertitle) {
    setsIsSubchapterLoading(true);
    await axiosPrivate.get(SUBCHAPTERCONTENT, {
      params: {
        businessplanid: businessplanid,
        subchapterid: subchapterid
      }
    },)
      .then((response) => {
        const subchapterContent = {
          subchapterstructure: response?.data.subchapterstructure,
        };
        if (subchapterContent) setContent(subchapterContent.subchapterstructure);
        setElementsShowTerminology([]);
        setChapterTitle(chaptertitle);
        setSubchapterTitle(subchaptertitle);
        setCurrentSubchapter(subchapterid);
        addChapterToExpandedChaptersIfNecessary(chapterid);
        setCurrentChapter(chapterid);
        setIsInitialLoading(false);
        setsIsSubchapterLoading(false);
        setSubchapterLoadedSuccessfully(true);
      })
      .catch((error) => {
        setIsInitialLoading(false);
        setsIsSubchapterLoading(false);
        setNavigationLoadedSuccessfully(false);
        setSubchapterLoadedSuccessfully(false);
        handleShowFail(error.response.data.error, true);
      });
  }

  /**
   * Expand the chapter of the selected subchapter
   * @param {*} chapterid 
   */
  function addChapterToExpandedChaptersIfNecessary(chapterid) {
    if (!expandedChapters.includes(chapterid)) {
      setExpandedChapters([...expandedChapters, chapterid]);
    }
  }

  // popup routine for fail and success allerts 
  function handleShowSuccess(popupMessage) {
    setPopUpMessage(popupMessage);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      setPopUpMessage("");
    }, 3000);
  }

  function handleShowFail(errorMessage, dontCloseAfter3Seconds) {
    setErrorMessage(errorMessage);
    setShowFail(true);
    if (!dontCloseAfter3Seconds) {
      setTimeout(() => {
        setShowFail(false);
        setErrorMessage("");
      }, 3000);
    }
  }

  /**
   * check if there is terminology for this question
   * @returns 
   */
  function showTerminology(terminology, index) {
    if (terminology.length > 0) {
      return (<div className="AskedQuestionIcon" onClick={() => toggleTerminologyVisibility(index)}></div>);
    }
  }

  function toggleTerminologyVisibility(index) {
    setElementsShowTerminology(prev => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  }

  //Question functions
  /**
   * set the changed value to true if the input change
   * @param {*} event 
   */
  const changeQuestionAnswer = (e, index) => {
    if (!elementsChanged.includes(index)) {
      setElementsChanged(prev => {
        return [...prev, index];
      });
    }
    setContent(prev => {
      const result = [...prev];
      result[index].answer = e.target.value;
      return result;
    });
  }

  /**
   * Calls the save methode if the input field lose the focus and answer is changed
   * @param {*} event 
   */
  const handleQuestionInputBlur = async (event, index) => {
    if (elementsChanged.includes(index)) {
      await SaveQuestion(index);
    }
  }

  /**
     * save the selected answer for the question
     */
  async function SaveQuestion(index) {
    setIsCurrentlySaving(true);
    await axiosPrivate
      .post(QUESTIONANSWER_URL, JSON.stringify({
        businessplanid: businessplanid,
        questionid: content[index].elementid,
        answer: content[index].answer
      }))
      .then((response) => {
        setElementsChanged(prev => {
          return prev.filter((i) => i !== index);
        });
        setIsCurrentlySaving(false);
        handleShowSuccess(response.data.message);
      })
      .catch((error) => {
        setIsCurrentlySaving(false);
        handleShowFail(error.response.data.error, false);
      });
  }

  //Table Functions
  /**
     * Show if there is a table template
     * @returns 
     */
  function showExel(tabletemplateid) {
    if (tabletemplateid != null) {
      return (
        <div onClick={() => downloadExel(tabletemplateid)} className="WExelcontainer">
          <p className="WExelDownload"> Tabellenvorlage </p><div className="WExelDownloadIcon"></div>
        </div>
      );
    }
  }

  /**
     * Download the table template 
     */
  async function downloadExel(tabletemplateid) {
    await axiosPrivate.get(DOWNLOADTABLE_URL, {
      params: {
        tabletemplateid: tabletemplateid
      },
      responseType: 'blob'
    }).then((response) => {
      fileDownload(response.data, response.headers['x-file-name']);
    }).catch((error) => {
      handleShowFail('Download fehlgeschlagen.', false);
    });
  }

  /**
    * Calls the save methode if the input field lose the focus and table is changed
    * @param {*} event 
    */
  const handleTableInputBlur = async (event, index) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (elementsChanged.includes(index)) {
        await SaveTable(index);
      }
    }
  }

  /**
  * save the data of the table
  */
  async function SaveTable(index) {
    setIsCurrentlySaving(true);
    await axiosPrivate
      .post(UPDATETABLE_URL, JSON.stringify({
        businessplanid: businessplanid,
        tableid: content[index].elementid,
        rows: content[index].rows,
        data: content[index].data
      }))
      .then((response) => {
        setElementsChanged(prev => {
          return prev.filter((i) => i !== index);
        });
        setIsCurrentlySaving(false);
        handleShowSuccess(response.data.message);
      })
      .catch((error) => {
        setIsCurrentlySaving(false);
        handleShowFail(error.response.data.error, false);
      });
  }

  const changeTableRow = (e, rowIndex, tableIndex) => {
    if (!elementsChanged.includes(tableIndex)) {
      setElementsChanged(prev => {
        return [...prev, tableIndex];
      });
    }
    setContent(prev => {
      const result = [...prev];
      result[tableIndex].rows[rowIndex].rowtitle = e.target.value;
      return result;
    });
  }

  const changeTableData = (e, rowIndex, columnIndex, tableIndex) => {
    if (!elementsChanged.includes(tableIndex)) {
      setElementsChanged(prev => {
        return [...prev, tableIndex];
      });
    }
    setContent(prev => {
      const result = [...prev];
      result[tableIndex].data[rowIndex][columnIndex] = e.target.value;
      return result;
    });
  }

  const getStyleForFirstColumn = (rows) => {
    const lengthArray = rows.map((elem, index) => elem.rowtitle.length);
    return { minWidth: Math.max(...lengthArray) + 'ch' };
  }

  const getStyleForDataColumn = (data, indexForData, column) => {
    const lengthArray = data.map((elem, index) => elem[indexForData].length);
    lengthArray.push(column.length);
    return { minWidth: Math.max(...lengthArray) + 'ch' };
  }

  /**
   * Display output 
   */
  return (
    <div className="Wirtschaftsteil">
      <div className="WTitelNavigationBox">
        <div className="WTitleNavigation">
          <div className="WTitelBox">
            <div className="WBackToHome" onClick={() => navigate("/businessplanoverview/" + businessplanid)}></div>
            <h1 className="WTitel">{props.pageTitle}</h1>
          </div>
          <div className="WWhiteButtonBox">
            <button className="WWhiteButton" onClick={getPrevSubchapter}><div className="WWhiteButtenBackIcon"></div> Zurück</button>
            <button className="WWhiteButton" onClick={getNextSubchapter}>Weiter <div className="WWhiteButtenNextIcon"></div> </button>
          </div>
          <Navbar whiteNavbar={true} />
        </div>
      </div>
      {
        isInitialLoading &&
        <div className="WLoadingDiv">
          <LoadingSpinner />
        </div>
      }
      {
        !isInitialLoading && navigationLoadedSuccessfully &&
        <div className="WContentFlex">
          <div className="WKapitelBox">
            <div className="WKapitel">
              <div className="Chapter" >
                {chapters.map((chapter, chapterindex) => {
                  return (
                    <div key={chapterindex}>
                      <div className={currentChapter === chapter.chapterid ? "CurrrentSingleChapter" : "SingleChapter"} onClick={event => toggleChapterExpanded(chapter.chapterid)}>
                        <p className={currentChapter === chapter.chapterid ? "CurrentChapterText" : "ChapterText"}>{chapter.chaptertitle}</p>
                      </div>
                      {expandedChapters.includes(chapter.chapterid) &&
                        chapter.subchapters.map((subchapter, subchapterindex) => {
                          return (
                            <div key={subchapterindex}>
                              <div className="SubChapter" onClick={event => handleDifferentSubchapter(chapter.chapterid, chapter.chaptertitle, subchapter.subchapterid, subchapter.subchaptertitle)}>
                                <p className={currentSubchapter === subchapter.subchapterid ? "CurrentSubChapterText" : "SubChapterText"}>{subchapter.subchaptertitle}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {
            isSubchapterLoading &&
            <div className="WHauptteil">
              <LoadingSpinner />
            </div>
          }
          {
            !isSubchapterLoading && subchapterLoadedSuccessfully &&
            <div className="WHauptteil">
              <div className="WHHeading">
                <div className="WHHeadingFlexbox">
                  <p className="WHChapterHeading">{chapterTitle}</p>
                  <p className="WHSubChapterHeading"> {subchapterTitle}</p>
                </div>
              </div>
              <div className="WHContent">
                {content.map((subchapcontent, index) => {
                  if (subchapcontent.elementtype === "Instruction") {
                    return (
                      <div className="questionContent" key={'Instruction' + subchapcontent.elementid}>
                        <pre className="IntroduktionText">{subchapcontent.instructiontext}</pre>
                      </div>
                    );
                  }
                  else if (subchapcontent.elementtype === "Question") {
                    return (
                      <div className="questionContent" key={"Question" + subchapcontent.elementid}>
                        <div className="QuestionBox"><p className="AskedQuestion">{subchapcontent.question}</p>{showTerminology(subchapcontent.terminology, index)}</div>
                        <pre className="AskedQuestionHint">{subchapcontent.hint}</pre>
                        {
                          elementsShowTerminology.includes(index) &&
                          (<div className="ShowTerminology">
                            {subchapcontent.terminology.map((terme, i) => {
                              return (
                                <div className="SingleTerm" key={"Que" + index + "Term" + i}>
                                  <p className="TerminologyText" id="term">{terme.term} </p>
                                  <p className="TerminologyText"> &bull; {terme.definition}</p>
                                </div>
                              );
                            })}
                          </div>)
                        }
                        <form>
                          <textarea onChange={(e) => changeQuestionAnswer(e, index)} onBlur={async (e) => await handleQuestionInputBlur(e, index)} className="QuestionTextarea" id="Answer" value={subchapcontent.answer}></textarea>
                        </form>
                      </div>
                    );
                  } else if (subchapcontent.elementtype === "Table") {
                    return (
                      <div className="questionContent" key={'Table' + subchapcontent.elementid} >
                        <div className="WTableTitleBox">
                          <div className="WTableHintBox"><p className="WTableTitle">{subchapcontent.title} </p>{showTerminology(subchapcontent.terminology, index)}</div>
                          {showExel(subchapcontent.tabletemplateid)}
                        </div>
                        <pre className="AskedQuestionHint">
                          {subchapcontent.explanation}
                        </pre>
                        {
                          elementsShowTerminology.includes(index) &&
                          (<div className="ShowTerminology">
                            {subchapcontent.terminology.map((terme, ind) => {
                              return (
                                <div className="SingleTerm" key={"Tab" + index + "Term" + ind}>
                                  <p className="TerminologyText" id="term">{terme.term} </p>
                                  <p className="TerminologyText"> &bull; {terme.definition}</p>
                                </div>
                              );
                            })}
                          </div>)
                        }
                        <div className="WTableContainer">
                          <table className="WTable" onBlur={async (e) => await handleTableInputBlur(e, index)}>
                            <thead className="WTablehead">
                              <tr className="WRow">
                                <td style={getStyleForFirstColumn(subchapcontent.rows)}></td>
                                {subchapcontent.columns.map((column, indexColumn) => {
                                  return (
                                    <td key={"Tab" + index + "Column" + indexColumn} className="WWTHItem" style={getStyleForDataColumn(subchapcontent.data, indexColumn, column)}>{column}</td>
                                  );
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              {subchapcontent.rows.map((row, rowindex) => {
                                return (
                                  <tr key={"Tab" + index + "Datarow" + rowindex} className="WRow">
                                    <td className="WRowName" >
                                      <input className={row.highlight === true ? "tableInput tableBoldInput" : "tableInput"} onChange={(e) => changeTableRow(e, rowindex, index)} value={row.rowtitle} />
                                    </td>
                                    {subchapcontent.data[rowindex].map((data, columnindex) => {
                                      return (
                                        <td key={"Tab" + index + "Data" + columnindex} className="WRowData">
                                          <input className={row.highlight === true ? "tableInput tableDataInput tableBoldInput" : "tableInput tableDataInput"} onChange={(e) => changeTableData(e, rowindex, columnindex, index)} value={data} />
                                        </td>
                                      )
                                    })}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  }
                  return <div></div>;
                })}
              </div>
            </div>
          }
        </div>
      }
      <SuccessAlert show={showSuccess} onClose={() => setShowSuccess(false)}>
        <p className="rspMessageSuccess">{popupMessage}</p>
      </SuccessAlert>
      <FailAlert show={showFail} onClose={() => setShowFail(false)}>
        <p className="rspMessageError">{errorMessage}</p>
      </FailAlert>
      {
        isCurrentlySaving &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
      <Idle />
    </div>
  );
};

export default BusinessplanEditing;
