//Technology
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import AlertModal from "../../components/Modals/AlertModal"
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Navbar from "../../components/Navigation/Navbar";
import SuccessAlert from "../../components/Modals/SuccessAlert";
//Functions
import { downloadBusinessplanTextDocument, downloadBusinessplanPDFDocument } from "../../functions/DownloadFunctions";
var fileDownload = require('js-file-download');

const BusinessplanOverview = () => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const businessplanid = params.businessplanid;

    //api url paths
    const DashboardURL = "/businessplandashboard";
    const ANLAGE_URL = "/uploadattachment";
    const DOWNLOADANLAGE_URL = "/downloadattachment";
    const DELETEATTACHMENT_URL = "/deleteattachment";

    //useStates
    const [bpName, setbpName] = useState();
    const [bpAnlagen, setbpAnlagen] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [elementId, setElementId] = useState(false);
    const [elementFilename, setElementFilename] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [showDashboardContent, setShowDashboardContent] = useState(false);
    const [attachmentsLoading, setAttachmentsLoading] = useState(false);
    const [businessplanLoading, setBusinessplanLoading] = useState(false);
    const [businessPlanLocked, setBusinessPlanLocked] = useState(false);

    const getBusinessplan = async () => {
        await axiosPrivate.get(DashboardURL, {
            params: {
                businessplanid
            }
        })
            .then((response) => {
                if (isInitialLoading) setIsInitialLoading(false);
                setAttachmentsLoading(false);
                setBusinessplanLoading(false);
                const bpData = response?.data;
                if (bpData.locked === false) {
                    setbpAnlagen(bpData.attachments);
                } else {
                    setBusinessPlanLocked(true);
                }
                setbpName(bpData.title);
                setShowDashboardContent(true);
            })
            .catch((error) => {
                if (isInitialLoading) setIsInitialLoading(false);
                setAttachmentsLoading(false);
                setBusinessplanLoading(false);
                setAndDisplayErrorMessage(error.response.data.error);
            });
    }

    async function handleDownloadBusinessplanTextDocument() {
        setBusinessplanLoading(true);
        try {
            await downloadBusinessplanTextDocument(businessplanid, axiosPrivate);
        } catch (error) {
            setAndDisplayErrorMessage(error.message);
        }
        setBusinessplanLoading(false);
    }

    async function handleDownloadBusinessplanPDFDocument() {
        setBusinessplanLoading(true);
        try {
            await downloadBusinessplanPDFDocument(businessplanid, axiosPrivate);
        } catch (error) {
            setAndDisplayErrorMessage(error.message);
        }
        setBusinessplanLoading(false);
    }

    useEffect(() => {
        getBusinessplan();
    }, []);

    const setAndDisplaySuccessMessage = (successMsg) => {
        setShowSuccessMsg(true);
        setSuccessMsg(successMsg);
        setTimeout(() => { setShowSuccessMsg(false); setSuccessMsg(""); }, 3000);
    }

    const setAndDisplayErrorMessage = (errorMsg) => {
        setShowError(true);
        setErrMsg(errorMsg);
        setTimeout(() => { setShowError(false); setErrMsg(""); }, 3000);
    }

    /**
     * Download selected attachment
     * @param {*} event 
     * @param {*} documentid 
     */
    const downloadAttachment = async (event, documentid) => {
        await axiosPrivate.get(DOWNLOADANLAGE_URL, {
            params: {
                documentid
            },
            responseType: 'blob'
        })
            .then((response) => {
                fileDownload(response.data, response.headers['x-file-name']);
            })
            .catch((error) => {
                setAndDisplayErrorMessage('Download fehlgeschlagen.');
            });
    }

    /**
     * delete selected attachment
     * @param {*} key 
     */
    const deleteAttachment = async (key) => {
        setAttachmentsLoading(true);
        await axiosPrivate.delete(DELETEATTACHMENT_URL, {
            params: {
                documentid: key
            }
        })
            .then(async (response) => {
                setAndDisplaySuccessMessage(response.data.message);
                await getBusinessplan();
            })
            .catch((error) => {
                setAttachmentsLoading(false);
                setAndDisplayErrorMessage(error.response.data.error)
            });
    }

    /**
     * upload selected attachment
     * @param {*} event 
     */
    const uploadHandler = async (event) => {
        if (event.target.files[0]) {
            setAttachmentsLoading(true);
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            await axiosPrivate({
                method: "POST",
                url: ANLAGE_URL,
                params: { businessplanid },
                data: formData
            }).then(async (response) => {
                setAndDisplaySuccessMessage(response.data.message);
                await getBusinessplan();
            }).catch((error) => {
                setAttachmentsLoading(false);
                setAndDisplayErrorMessage(error.response.data.error);
            })
            document.getElementById('file-upload').value = "";
        }
    }

    /**
     * display output 
     */
    return (
        <div>
            {
                isInitialLoading && !showDashboardContent &&
                <div className="Dashboard">
                    <LoadingSpinner />
                </div>
            }
            {
                !isInitialLoading && showDashboardContent &&
                <div className="Dashboard">
                   
                    <div className="HContainerOben">
                         <div className="Navigation">
                        <div className="Navigationplace"></div>
                        <div className="WNavbar">
                            <Navbar />
                        </div>
                    </div>
                        <h1 className="HBusinessplanname" id="BPName">
                            {bpName}
                        </h1>
                        {
                            businessPlanLocked &&
                            <p className="HBusinessplanname">Dein Businessplan ist gesperrt worden.</p>
                        }
                        {
                            !businessPlanLocked &&
                            <div className="HKontentOben">
                                <div className="HKonzeptionBearbeiten" onClick={() => {
                                    navigate("/conceptualpart/" + businessplanid);
                                }}>
                                    <h2 className="HTK">Konzeptionsteil <br></br>
                                        bearbeiten </h2>
                                </div>
                                <div className="HWirtschaftBearbeiten" onClick={() => {
                                    navigate("/economicpart/" + businessplanid);
                                }}>
                                    <h2 className="HTW">Wirtschaftsteil <br></br>
                                        bearbeiten </h2>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="HDivContainerUnten">
                        <div className={businessPlanLocked ? "HDivAnlageBPLocked" : "HDivAnlage"}>
                            {
                                !businessPlanLocked &&
                                <div className="HAnlageHinzufügen">
                                    <div className="HBAnlage">
                                        <h3 className="HTAnlage">Anlagen</h3>
                                    </div>
                                    <div className="HContainerAnlageHinzufügen">
                                        <div className="HAnlagen">
                                            {
                                                attachmentsLoading && showDashboardContent && <LoadingSpinner />
                                            }
                                            {
                                                !attachmentsLoading && showDashboardContent &&
                                                bpAnlagen.map((element) => (
                                                    <div className="HAnlage" key={element._id}>
                                                        <div className="HomeAnlageIcon">
                                                        </div>
                                                        <p className="HAnlageText">{element.filename}</p>
                                                        <div className="Home-Anlage-Herunterladen" onClick={event => downloadAttachment(event, element._id)}>
                                                        </div>
                                                        <div className="Home-Anlage-Löschen" onClick={event => { setShowDeleteAlert(true); setElementId(element._id); setElementFilename(element.filename) }}>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="HBTAnlageHinzufügen">
                                            <label htmlFor="file-upload" className="Custom-file-upload" >
                                                Anlage hinzufügen
                                            </label>
                                            <input className="DashboardFileUpload" id="file-upload" type="file" name="file" onChange={uploadHandler} />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="HAnlageHinzufügen">
                                <div className="HBAnlage">
                                    <h3 className="HTAnlage"> Kontaktiere uns</h3>

                                </div>
                                <div className="HContainerKontakt" onClick={() => {
                                    window.open("https://www.b3-beyrow.de/kontakt/");
                                }}>
                                </div>
                            </div>
                            {
                                !businessPlanLocked &&
                                <div className="HAnlageHinzufügen">
                                    <div className="HBAnlage">
                                        <h3 className="HTAnlage"> Businessplan herunterladen</h3>
                                    </div>
                                    <div className="HContainerDownload">
                                        {
                                            businessplanLoading && showDashboardContent && <LoadingSpinner />
                                        }
                                        {
                                            !businessplanLoading && showDashboardContent &&
                                            <div className="HContainerDownload">
                                                <button className="Downloadbutton" onClick={handleDownloadBusinessplanTextDocument}>
                                                    <div className="HDownloadSymbol"></div> Download Word
                                                </button>
                                                <button className="Downloadbutton" id="DB2" onClick={handleDownloadBusinessplanPDFDocument} >
                                                    <div className="HDownloadSymbol"></div> Download PDF
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <AlertModal
                title={"Anlage löschen?"}
                name={"Ja, Anlage löschen"}
                button="Abbrechen"
                onClose={() =>
                    setShowDeleteAlert(false)
                }
                show={showDeleteAlert}
                onSubmit={() => { setShowDeleteAlert(false); deleteAttachment(elementId); }}
            >
                <p>
                    Du bist dabei die Anlage <b> {elementFilename} </b> zu löschen... Alle Daten werden unwiderruflich aus der Datenbank entfernt.
                </p>
            </AlertModal>
            <SuccessAlert show={showSuccessMsg} onClose={() => { setShowSuccessMsg(false); setSuccessMsg(""); }}>{<p className="rspMessageSuccess">{successMsg}</p>}</SuccessAlert>
            <FailAlert show={showError} onClose={() => { setShowError(false); setErrMsg("") }}>{<p className="rspMessageError">{errMsg}</p>}</FailAlert>
            <Idle />
        </div>
    );
};

export default BusinessplanOverview;
