//Technology
import { useState } from "react";
import { Link } from "react-router-dom";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
import img2 from "../../assets/Auth/Wireframe_Mockup_Login_Registrierung.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
//Components
import FailAlert from "../../components/Modals/FailAlert";

export default function ForgotPasswordThankYou() {
  //useStates
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);

  async function handleDownloadTac() {
    try {
      await downloadTaC();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    try {
      await downloadPrivacyStatements();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  function getLinkPart() {
    return (
      <>
        <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
        <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
        <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
      </>
    )
  }

  return (
    <div className="bodyCustomerLogin forgotpw-thanks-container">
      {/* container for left side of the customer login site */}
      <div className="containerForLeft">
        {/* container for the heading and paragraph that gets displayed on the left side */}
        <div className="containerForHeadingAndParagraph">
          <h1 className="headingOne">Erstelle deinen Businessplan!</h1>
          <p className="paragraphOne">
            Zu jeder Unternehmensgründung gehört auch ein Businessplan.{" "}
            <br /> Bei uns hast du die Möglichkeit einfach aber effektiv deine Gründungsidee zu verwirklichen!
          </p>
        </div>

        <img src={img2} className="imageOfApp" alt="" />
        <div className="containerForParagraphs">
          {getLinkPart()}
        </div>
      </div>

      {/* container for the right side of the customer login site */}
      <div className="containerForLoginThankYou">
        {/* container for the components of the customer login such as inputs, button etc */}
        <div className="customerLoginThankYou">
          <img src={img1} className="logoB3ThankYou" alt="" />
          <h1 className="headingThankYou">Vielen Dank!</h1>
          <p className="pCheckYourMails">Bitte überprüfe deine E-Mails.</p>
          <p className="pYouWillReceiveLink">
            Sofern unter der E-Mail ein Konto existiert, wirst<br />
            du in Kürze einen Link erhalten, mit dem du dein<br />
            Passwort ändern kannst.
          </p>

          <p className="registerHere">Zurück zur <Link to="/customerlogin" className="hoverLink">Anmeldung</Link></p>
        </div>
        <div className="customerLoginLinkPartResponsive">
          {getLinkPart()}
        </div>
      </div>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
    </div>
  );
}
