//Technology
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
//Auth
import useAuth from "../../hooks/useAuth";
//Components
import Modal from "../Modals/Modal";

function Idle(props) {
  const { auth, setAuth } = useAuth();
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [errMsgLogout, setErrMsgLogout] = useState("");
  const navigate = useNavigate();

  const sessionTimeoutRef = useRef(null);
  const openModalTimeoutRef = useRef(null);

  const LOGOUT_URL = "/logout";
  const events = ['click', 'scroll', 'keydown'];

  const eventHandler = (eventType) => {
    if (sessionTimeoutRef) {
      startTimer();
    }
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler)
    })
    startTimer();
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    })
    if (sessionTimeoutRef) {
      clearTimeout(sessionTimeoutRef.current);
    }
  };

  const startTimer = () => {
    if (sessionTimeoutRef) {
      clearTimeout(sessionTimeoutRef.current);
    }
    sessionTimeoutRef.current = setTimeout(onIdle, 1000 * 60 * process.env.REACT_APP_TIMEOUT_MINUTES);
  }

  const onIdle = () => {
    //Check if user is logged in
    if (Object.keys(auth).length !== 0) {
      document.activeElement.blur();
      setShowIdleModal(true);
      if (openModalTimeoutRef) {
        clearTimeout(openModalTimeoutRef.current);
      }
      openModalTimeoutRef.current = setTimeout(logout, 1000 * 60 * process.env.REACT_APP_TIMEOUT_CHANCE_TO_ACTIVATE_MINUTES);
    }
  }

  useEffect(() => {
    addEvents();

    return (() => {
      removeEvents();
    })
  }, [])

  const handleStayActiveButton = () => {
    clearTimeout(openModalTimeoutRef.current);
    setShowIdleModal(false);
  }

  const handleLogoutButton = () => {
    clearTimeout(openModalTimeoutRef.current);
    logout();
  }

  const logout = () => {
    axios
      .get(LOGOUT_URL, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true
      })
      .then((response) => {
        setAuth({});
        navigate(props.isEmployee ? '/employeelogin' : '/customerlogin');
      }).catch((error) => {
        setErrMsgLogout(error.response.data.error);
      });
  }

  return (
    <Modal
      title="Du bist inaktiv!"
      name="Angemeldet bleiben"
      button="Ausloggen"
      onClose={handleLogoutButton}
      show={showIdleModal}
      onSubmit={handleStayActiveButton}>
      <p>Du bist seit 30 Minuten inaktiv. Sofern du nicht reagierst, wirst du in einer Minute ausgeloggt.</p>
      <p className={errMsgLogout ? "errmsgIdleModal" : "offscreen"}>{errMsgLogout}</p>
    </Modal>
  );
}

export default Idle;
