//Technology
import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import DropFileInput from "../../components/FileUpload/DropFile";
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Modal from "../../components/Modals/Modal";
import SuccessAlert from "../../components/Modals/SuccessAlert";
//Functions
import getNavBarDependingOnRole from "../../functions/NavbarFunctions";
//fileDownload
var fileDownload = require('js-file-download');

function TacAndPrivacyStatements() {
    const axiosPrivate = useAxiosPrivate();

    //useStates for data
    const [privacy, setPrivacy] = useState([]);
    const [agbs, setAgbs] = useState([]);

    //useStates for file upload
    const [showAgb, setShowAGB] = useState(false);
    const [showDatenschutz, setShowDatenschutz] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [versionSelect, setVersionSelect] = useState("");
    const [dateSelect, setDateSelect] = useState("");
    const [errMsg, setErrMsg] = useState("");

    //useStates for success alert
    const [showSuccess, setShowSuccess] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");

    //useStates for fail alerts
    const [failAlertVisibleTac, setFailAlertVisibleTac] = useState(false);
    const [failAlertVisiblePrivacy, setFailAlertVisiblePrivacy] = useState(false);
    const [failAlertVisibleDownload, setFailAlertVisibleDownload] = useState(false);
    const [errorMessageTac, setErrorMessageTac] = useState("");
    const [errorMessagePrivacy, setErrorMessagePrivacy] = useState("");
    const [errorMessageDownload, setErrorMessageDownload] = useState("");

    //useStates for loading process
    const [isLoadingTaCs, setIsLoadingTaCs] = useState(true);
    const [isLoadingPrivacyStatements, setIsLoadingPrivacyStatements] = useState(true);
    const [getTacDataSuccessFull, setGetTacDataSuccessFull] = useState(false);
    const [getPrivacyDataSuccessFull, setGetPrivacyDataSuccessFull] = useState(false);
    const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

    const handleInputChange = (e) => {
        let regex = /^[\.0-9]*$$/  // eslint-disable-line
        setErrMsg("");
        const { id, value } = e.target;
        if (id === "version") {
            if (value.search(',') > -1) {
                e.preventDefault();
                setErrMsg('Die Version muss in dem Format Nummer.Nummer angegeben werden.');
            } else {
                if ((value.match(regex) === null)) {
                    e.preventDefault()
                    setErrMsg("Die Version muss in dem Format Nummer.Nummer angegeben werden.");
                } else {
                    setVersionSelect(value);
                }
            }
        }
        if (id === "datum") {
            setDateSelect(value)
        }
    }

    const onFileChange = async (file) => {
        setSelectedFile(file)
        setErrMsg("");
    }

    const uploadFile = async (route) => {
        const formData = new FormData();
        if (versionSelect.search(',') > -1) {
            setErrMsg("Upload fehlgeschlagen. Die Version muss in dem Format Nummer.Nummer angegeben werden.");
        } else if (!selectedFile) {
            setErrMsg("Upload fehlgeschlagen. Es wurde keine Datei ausgewählt.");
        } else {
            setIsCurrentlyProcessing(true);
            formData.append("file", selectedFile, selectedFile.name);
            try {
                const result = await axiosPrivate.post(route + '?validitydate=' + dateSelect + '&version=' + versionSelect + '', formData, {
                    headers: { "Content-Type": "application/pdf" }
                });
                if (result.status === 200) {
                    if (route === '/uploadtermsandconditions') {
                        getTaCs();
                        setShowAGB(false);
                    } else {
                        getPrivacyStatements();
                        setShowDatenschutz(false);
                    }
                    setPopUpMessage(result.data.message);
                    setShowSuccess(true);
                    setErrMsg("");
                    setIsCurrentlyProcessing(false);
                    setTimeout(() => { setShowSuccess(false) }, 3000);
                    setSelectedFile(null);
                    setDateSelect("");
                    setVersionSelect("");
                }
            } catch (error) {
                setErrMsg(error.response.data.error);
                setIsCurrentlyProcessing(false);
            }
        }
    }

    const getTaCsAndPrivacyStatements = async () => {
        await getTaCs();
        await getPrivacyStatements();
    }

    const getTaCs = async () => {
        await axiosPrivate
            .get('/alltermsandconditions')
            .then((response) => {
                setAgbs(response.data.files);
                setGetTacDataSuccessFull(true);
                setIsLoadingTaCs(false);
                setIsCurrentlyProcessing(false);
            }).catch((error) => {
                setErrorMessageTac(error.response.data.error);
                setFailAlertVisibleTac(true);
                setIsLoadingTaCs(false);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisibleTac(false) }, 3000)
            });
    }

    const getPrivacyStatements = async () => {
        await axiosPrivate
            .get('/allprivacystatements')
            .then((response) => {
                setPrivacy(response.data.files);
                setGetPrivacyDataSuccessFull(true);
                setIsLoadingPrivacyStatements(false);
                setIsCurrentlyProcessing(false);
            }).catch((error) => {
                setErrorMessagePrivacy(error.response.data.error);
                setFailAlertVisiblePrivacy(true);
                setIsLoadingPrivacyStatements(false);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisiblePrivacy(false) }, 3000)
            });
    }

    useEffect(() => {
        getTaCsAndPrivacyStatements();
    }, []);

    async function downloadFile(item, routeParam) {
        setIsCurrentlyProcessing(true);
        await axiosPrivate.get(
            routeParam + "?documentid=" + item.id,
            {
                responseType: 'blob',
                data: {
                    id: item.id
                }
            }
        )
            .then((response) => {
                fileDownload(response.data, response.headers['x-file-name']);
                setIsCurrentlyProcessing(false);
            })
            .catch((error) => {
                setErrorMessageDownload("Download fehlgeschlagen.");
                setFailAlertVisibleDownload(true);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisibleDownload(false) }, 3000);
            })
    }

    return (
        <div className="bodyOfTacAndPsPage">
            {getNavBarDependingOnRole('admin')}
            <div>
                <div className='listen-header-container'>
                    <h1 className="headingTacAndPrivacyStatements">AGB und Datenschutz</h1>
                    <div className="listen-btn-container">
                        <Modal title="AGB Hochladen" name="Hochladen" button="Abbrechen" onClose={() => { setShowAGB(false); setSelectedFile(null); setErrMsg(""); setDateSelect(""); setVersionSelect(""); }} show={showAgb} onSubmit={() => {
                            uploadFile('/uploadtermsandconditions')
                        }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="tacPsAddingColum">
                                        <label htmlFor="gueltigab">Gültig ab</label>
                                        <input className="form-control width100" type="date" value={dateSelect} onChange={handleInputChange} id="datum" name="gueltigab" aria-label="Default select example" />
                                    </div>
                                    <div className="tacPsAddingColum">
                                        <label htmlFor="version">Version</label>
                                        <input className="form-control width100" type="text" value={versionSelect} placeholder="x.x" onChange={handleInputChange} id="version" name="version" aria-label="Default select example" />
                                    </div>
                                    <div>
                                        <DropFileInput
                                            onFileChange={(files) => onFileChange(files)} filename={selectedFile ? selectedFile.name : ""}
                                        />
                                    </div>
                                    <div>
                                        <br />
                                        {errMsg && <p className="email-error-text">{errMsg}</p>}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal title="Datenschutz hochladen" name="Hochladen" button="Abbrechen" onClose={() => { setShowDatenschutz(false); setSelectedFile(null); setErrMsg(""); setDateSelect(""); setVersionSelect(""); }} show={showDatenschutz} onSubmit={() => {
                            uploadFile('/uploadprivacystatement')
                        }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="tacPsAddingColum">
                                        <label htmlFor="gueltigab">Gültig ab</label>
                                        <input className="form-control width100" type="date" value={dateSelect} onChange={handleInputChange} id="datum" name="gueltigab" aria-label="Default select example" />
                                    </div>
                                    <div className="tacPsAddingColum">
                                        <label htmlFor="version">Version</label>
                                        <input className="form-control width100" type="text" value={versionSelect} placeholder="x.x" onChange={handleInputChange} id="version" name="version" aria-label="Default select example" />
                                    </div>
                                    <div>
                                        <DropFileInput
                                            onFileChange={(files) => onFileChange(files)} filename={selectedFile ? selectedFile.name : ""}
                                        />

                                    </div>
                                    <div>
                                        <br />
                                        {errMsg && <p className="email-error-text">{errMsg}</p>}
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
                <div className='listen-outer-container'>
                    <div className='listen-bereich-container liste-left'>
                        <div className='listen-sub-header-container'>
                            <h1 className='listen-header'>AGB</h1>
                        </div>

                        <div className='listen-container-content'>
                            <div className="agbs-tabelle">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="status-td-th">Status</th>
                                            <th className="name-td-th">Name</th>
                                            <th className="version-td-th">Version</th>
                                            <th className="validFrom-td-th">Gültig ab</th>
                                            <th className="button-td-th"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoadingTaCs &&
                                            <tr>
                                                <td>
                                                    <LoadingSpinner />
                                                </td>
                                            </tr>
                                        }
                                        {
                                            !isLoadingTaCs && getTacDataSuccessFull &&
                                            (agbs.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="status-td-th"><p className={`${item.status === 'ungültig' ? 'ungueltig-class' : ''} ${item.status === 'wartend' ? 'wart-class' : ''} ${item.status === 'gültig' ? 'gueltig-class' : ''}`}>{item.status}</p></td>
                                                    <td className="name-td-th"><p>{item.filename}</p></td>
                                                    <td className="version-td-th"><p>{item.version}</p></td>
                                                    <td className="validFrom-td-th"><p>{item.validityDate}</p></td>
                                                    <td className="button-td-th">
                                                        <div className="tandc-download-btn"
                                                            onClick={() => { downloadFile(item, '/specifictermsandconditions') }} />
                                                    </td>
                                                </tr>)
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className='listen-containers'>
                                <span className="anlage-button">
                                    <div className="tandc-upload-button" onClick={() => { setShowAGB(true) }}></div>
                                    <p className='listen-texts listen-footer' onClick={() => { setShowAGB(true) }}>Anlagen hinzufügen</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='listen-bereich-container liste-right'>
                        <div className='listen-sub-header-container'>
                            <h1 className='listen-header'>Datenschutz</h1>
                        </div>
                        <div className='listen-container-content'>
                            <div className="agbs-tabelle">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="status-td-th">Status</th>
                                            <th className="name-td-th">Name</th>
                                            <th className="version-td-th">Version</th>
                                            <th className="validFrom-td-th">Gültig ab</th>
                                            <th className="button-td-th"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoadingPrivacyStatements &&
                                            <tr>
                                                <td>
                                                    <LoadingSpinner />
                                                </td>
                                            </tr>
                                        }
                                        {!isLoadingPrivacyStatements && getPrivacyDataSuccessFull &&
                                            (privacy.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="status-td-th"><p className={`${item.status === 'ungültig' ? 'ungueltig-class' : ''} ${item.status === 'wartend' ? 'wart-class' : ''} ${item.status === 'gültig' ? 'gueltig-class' : ''}`}>{item.status}</p></td>
                                                    <td className="name-td-th"><p>{item.filename}</p></td>
                                                    <td className="version-td-th"><p>{item.version}</p></td>
                                                    <td className="validFrom-td-th"><p>{item.validityDate}</p></td>
                                                    <td className="button-td-th">
                                                        <div className="tandc-download-btn"
                                                            onClick={() => { downloadFile(item, '/specificprivacystatement') }} />
                                                    </td>
                                                </tr>
                                            )))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='listen-containers'>
                                <span className="anlage-button" >
                                    <div className="tandc-upload-button" onClick={() => { setShowDatenschutz(true) }}></div>
                                    <p className='listen-texts listen-footer' onClick={() => { setShowDatenschutz(true) }}>Anlagen hinzufügen</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SuccessAlert show={showSuccess} onClose={() => setShowSuccess(false)}>
                <p className="rspMessageSuccess">{popUpMessage}</p>
            </SuccessAlert>
            <FailAlert show={failAlertVisibleTac} onClose={() => setFailAlertVisibleTac(false)}><p className="rspMessageError">{errorMessageTac}</p></FailAlert>
            <FailAlert show={failAlertVisiblePrivacy} onClose={() => setFailAlertVisiblePrivacy(false)}><p className="rspMessageError">{errorMessagePrivacy}</p></FailAlert>
            <FailAlert show={failAlertVisibleDownload} onClose={() => setFailAlertVisibleDownload(false)}><p className="rspMessageError">{errorMessageDownload}</p></FailAlert>
            {
                (!isLoadingTaCs || !isLoadingPrivacyStatements) && isCurrentlyProcessing &&
                <div className="saveLoadingSpinnerArea">
                    <LoadingSpinner />
                </div>
            }
            <Idle isEmployee={true} />
        </div >
    );
}

export default TacAndPrivacyStatements;