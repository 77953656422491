//Components
import Idle from "../../components/Auth/Idle";
import TableTemplatesList from "../../components/EmployeeArea/TableTemplatesList";
//Functions
import getNavBarDependingOnRole from "../../functions/NavbarFunctions";

export default function TableTemplatesOverview(props) {
    return (
        <div className="bodyOfEmployeeAreaPages">
            {getNavBarDependingOnRole(props.role)}
            <div className="headingAreaTableTemplatesOverview">
                <h1 className="headingTableTemplatesOverview">Tabellenvorlagen</h1>
            </div>
            <div className="tableAreaTableTemplatesOverview">
                <TableTemplatesList></TableTemplatesList>
            </div>
            <Idle isEmployee={true}/>
        </div>
    );
}
