//Technology
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Functions
import getNavBarDependingOnRole from "../../functions/NavbarFunctions";
//Components
import Idle from "../../components/Auth/Idle";
import Modal from "../../components/Modals/Modal";
import SuccessAlert from "../../components/Modals/SuccessAlert";
import FailAlert from "../../components/Modals/FailAlert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
//fileDownload
var fileDownload = require('js-file-download');

export default function BusinessPlanStructure() {
    const axiosPrivate = useAxiosPrivate();

    //api url paths
    const downloadBusinessPlanStructureURL = '/businessplanstructure';
    const uploadBusinessPlanStructureURL = '/uploadbusinessplanstructure';

    //useStates
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [modalErrMsg, setModalErrMsg] = useState("");
    const [showModalDetailedErrMsg, setShowModalDetailedErrMsg] = useState(false);
    const [modalCurrentObjectId, setModalCurrentObjectId] = useState("");
    const [modalDetailedErrMsg, setModalDetailedErrMsg] = useState("");
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadIsLoading, setUploadIsLoading] = useState(false);

    async function handleDownloadBusinessplanStructure() {
        await axiosPrivate
            .get(downloadBusinessPlanStructureURL, {
                responseType: 'blob'
            })
            .then((response) => {
                fileDownload(response.data, 'BusinessplanStruktur.zip');
            }).catch((error) => {
                setErrMsg('Download fehlgeschlagen.');
                setShowFailAlert(true);
                setTimeout(() => { setShowFailAlert(false); setErrMsg(""); }, 3000);
            });
    }

    const uploadInputOnFileChange = (event) => {
        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        } else {
            setSelectedFile(null);
        }
    }

    function handleUploadModalOnClose(tablestructureid) {
        setShowUploadModal(false);
        setSelectedFile(null);
        document.getElementById('bpstructure-file-upload').value = "";
        setModalErrMsg("");
        setShowModalDetailedErrMsg(false);
        setModalDetailedErrMsg("");
        setModalCurrentObjectId("");
    }

    async function handleUploadBPStructure() {
        if (!selectedFile) {
            setModalErrMsg("Upload fehlgeschlagen. Es wurde keine Datei ausgewählt.");
            setShowModalDetailedErrMsg(false);
            setModalDetailedErrMsg("");
            setModalCurrentObjectId("");
        } else {
            setModalErrMsg("");
            setShowModalDetailedErrMsg(false);
            setModalDetailedErrMsg("");
            setModalCurrentObjectId("");
            setUploadIsLoading(true);
            const formData = new FormData();
            formData.append("file", selectedFile);
            await axiosPrivate
                .post(uploadBusinessPlanStructureURL,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                ).then((response) => {
                    setShowUploadModal(false);
                    document.getElementById('bpstructure-file-upload').value = "";
                    setSelectedFile(null);
                    setModalErrMsg("");
                    setModalDetailedErrMsg("");
                    setModalCurrentObjectId("");
                    setUploadIsLoading(false);
                    setSuccessMsg(response.data.message);
                    setShowSuccessAlert(true);
                    setTimeout(() => { setShowSuccessAlert(false); setSuccessMsg(""); }, 3000);
                }).catch((error) => {
                    setUploadIsLoading(false);
                    setModalErrMsg(error.response.data.error);
                    setModalDetailedErrMsg(error.response.data.detailederror);
                    setModalCurrentObjectId(error.response.data.currentobjectid)
                });
        }
    }

    return (
        <div className="bodyOfEmployeeAreaPages">
            {getNavBarDependingOnRole('admin')}
            <div className="BPSTRUCTUREheadingArea">
                <h1 className="BPSTRUCTUREheading">Businessplanstruktur</h1>
            </div>
            <div className="BPSTRUCTUREbodyArea">
                <div className="BPSTRUCTUREbuttonArea">
                    <div className="BPSTRUCTUREbutton" onClick={handleDownloadBusinessplanStructure}>
                        <h2>Businessplanstruktur herunterladen</h2>
                    </div>
                    <div className="BPSTRUCTUREbutton" onClick={() => { setShowUploadModal(true); }}>
                        <h2>Businessplanstruktur hochladen</h2>
                    </div>
                </div>
            </div>
            <Modal
                title="Businessplanstruktur hochladen?"
                name="Hochladen"
                button="Abbrechen"
                onClose={handleUploadModalOnClose}
                show={showUploadModal}
                onSubmit={handleUploadBPStructure}
            >
                <p>Um die Businessplanstruktur zu aktualisieren muss eine zip Datei mit den nötigen Strukturdaten ausgewählt werden. Nach der Aktualisierung werden neue Businesspläne mit der neu hochgeladenen Struktur erstellt. Alte Businesspläne behalten die zum Zeitpunkt der Erstellung gültige Struktur bei.</p>
                <input className="BPSTRUCTUREUploadInput" id="bpstructure-file-upload" type="file" accept=".zip" name="bpstructurefile" onChange={uploadInputOnFileChange} />
                {
                    modalErrMsg &&
                    <p className="BPSTRUCTUREerrMsgModal">{modalErrMsg}</p>
                }
                {
                    (modalDetailedErrMsg || modalCurrentObjectId) &&
                    <div onClick={() => { setShowModalDetailedErrMsg(!showModalDetailedErrMsg); }}><p className="BPSTRUCTUREshowDetailedError">{showModalDetailedErrMsg ? "Detaillierten Fehler ausblenden" : "Detaillierten Fehler anzeigen"}</p></div>
                }
                {
                    modalCurrentObjectId && showModalDetailedErrMsg &&
                    <p className="BPSTRUCTUREerrMsgModal">{"Objekt-ID: " + modalCurrentObjectId}</p>
                }
                {
                    modalDetailedErrMsg && showModalDetailedErrMsg &&
                    <p className="BPSTRUCTUREerrMsgModal">{"Fehler: " + modalDetailedErrMsg}</p>
                }
                {
                    uploadIsLoading &&
                    <LoadingSpinner></LoadingSpinner>
                }
            </Modal>
            <SuccessAlert show={showSuccessAlert} onClose={() => { setShowSuccessAlert(false); setSuccessMsg(""); }}><p className="rspMessageSuccess">{successMsg}</p></SuccessAlert>
            <FailAlert show={showFailAlert} onClose={() => { setShowFailAlert(false); setErrMsg(""); }}>{<p className="rspMessageError">{errMsg}</p>}</FailAlert>
            <Idle isEmployee={true} />
        </div >
    );
}