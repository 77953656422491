import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedRoles, navigationPath }) => {
    const { auth } = useAuth();
    const location = useLocation();
    
    return (
        auth?.role && auth?.acceptedTacAndPs === true
            ? allowedRoles.includes(auth?.role)
                ? <Outlet />
                : <Navigate to="/unauthorized" state={{ from: location }} replace />
            : <Navigate to={navigationPath || "/customerlogin"} state={{ from: location }} replace />
    );
}

export default RequireAuth;