import { Link } from "react-router-dom";

const Missing = () => {
    return (
        <article style={{ padding: "20px" }}>
            <h1>Oops!</h1>
            <p>Seite nicht gefunden.</p>
            <div>
                <Link to="/">Besuche unsere Startseite</Link>
            </div>
        </article>
    );
}

export default Missing;