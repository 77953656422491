import { Route, Routes } from "react-router-dom";

//protectedRoutes
import RequireAuth from './components/Auth/RequireAuth';
import RequireTacAndPsAuth from './components/Auth/RequireTacAndPsAuth';
import Unauthorized from './components/Auth/Unauthorized';

//signup
import Signup from "./pages/Signup/Signup";
import SignupSuccess from "./pages/Signup/SignupSuccess";
import ConfirmEmail from "./pages/Login/ConfirmEmail";

//login
import EmployeesLogin from "./pages/Login/EmployeesLogin";
import CustomerLogin from "./pages/Login/CustomerLogin";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ForgotPasswordThankYou from "./pages/Login/ForgotPasswordThankYou";
import ForgotPasswordReset from "./pages/Login/ForgotPasswordReset";
import ActivateAfterLock from "./pages/Login/ActivateAfterLock";

//all roles
import AccountSettings from "./pages/AccountSettings/AccountSettings";

//employee area
import Customeroverview from "./pages/EmployeeArea/Customeroverview";
import TableTemplateOverview from "./pages/EmployeeArea/TableTemplatesOverview";
import Employees from "./pages/EmployeeArea/Employees";
import TacAndPrivacyStatements from "./pages/EmployeeArea/TacAndPrivacyStatements";

//customer area
import FirstVisit from "./pages/CustomerArea/FirstVisit";
import BusinessplanOverview from "./pages/CustomerArea/BusinessplanOverview";
import BusinessplanEditing from "./pages/CustomerArea/BusinessplanEditing";

//tac and privacy statements
import AcceptTacAndPs from "./pages/AcceptTacAndPs/AcceptTacAndPs";

//missing
import Missing from "./components/Auth/Missing";
import BusinessPlanStructure from "./pages/EmployeeArea/BusinessPlanStructure";

const Roles = {
  'Customer': 'customer',
  'Consultant': 'consultant',
  'Admin': 'admin'
}

function App() {
  return (
    <div>
      <Routes>
        {/* public routes */}
        {/* signup  */}
        <Route path="/Signup" element={<Signup />} />
        <Route path="/SignupSuccess" element={<SignupSuccess />} />
        <Route path="/confirmemail/:token" element={<ConfirmEmail />} />
        {/* login  */}
        <Route path="*" element={<Missing />} />
        <Route path="/" element={<CustomerLogin />} />
        <Route path="/customerlogin" element={<CustomerLogin />} />
        <Route path="/employeelogin" element={<EmployeesLogin />} />
        <Route path="/customerlogin/forgotpassword" element={<ForgotPassword isEmployee={false} />} />
        <Route path="/employeelogin/forgotpassword" element={<ForgotPassword isEmployee={true} />} />
        <Route path="/ForgotPasswordThankYou" element={<ForgotPasswordThankYou />} />
        <Route path="/resetpassword/:token" element={<ForgotPasswordReset />} />
        <Route path="/activateaccountafterlocking/:token" element={<ActivateAfterLock />} />
        {/*Unauthorized*/}
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* protected employee routes */}
        <Route element={<RequireAuth allowedRoles={[Roles.Consultant, Roles.Admin]} navigationPath="/employeelogin" />}>
          <Route path="/employee/accountsettings" element={<AccountSettings isEmployee={true} />} />
        </Route>

        {/* protected admin routes */}
        <Route element={<RequireAuth allowedRoles={[Roles.Admin]} navigationPath="/employeelogin" />}>
          <Route path="/admin/customerlist" element={<Customeroverview role="admin" />} />
          <Route path="/admin/tabletemplatesoverview" element={<TableTemplateOverview role="admin" />} />
          <Route path="/admin/tacandps" element={<TacAndPrivacyStatements />} />
          <Route path="/admin/employees" element={<Employees />} />
          <Route path="/admin/businessplanstructure" element={<BusinessPlanStructure />} />
        </Route>

        {/* protected consultant routes */}
        <Route element={<RequireAuth allowedRoles={[Roles.Consultant]} navigationPath="/employeelogin" />}>
          <Route path="/consultant/customerlist" element={<Customeroverview role="consultant" />} />
          <Route path="/consultant/tabletemplatesoverview" element={<TableTemplateOverview role="consultant" />} />
        </Route>

        {/* protected customer routes */}
        <Route element={<RequireAuth allowedRoles={[Roles.Customer]} navigationPath="/customerlogin" />}>
          <Route path="/customer/accountsettings" element={<AccountSettings isEmployee={false} />} />
          <Route path="/HomeFirstVisit" element={<FirstVisit />} />
          <Route path="/businessplanoverview/:businessplanid" element={<BusinessplanOverview />} />
          <Route path="/conceptualpart/:businessplanid" element={<BusinessplanEditing chaptersroute="/conceptualpartchapters" pageTitle="Konzeptionsteil" />} />
          <Route path="/economicpart/:businessplanid" element={<BusinessplanEditing chaptersroute="/economicpartchapters" pageTitle="Wirtschaftsteil" />} />
        </Route>

        {/* Part for Tac and Ps acceptance */}
        <Route element={<RequireTacAndPsAuth allowedRoles={[Roles.Consultant, Roles.Admin]} navigationPath="/employeelogin" />}>
          <Route path="/consultant/accepttacandps" element={<AcceptTacAndPs role="consultant" acceptedValidTac={false} acceptedValidPs={false} />} />
          <Route path="/consultant/accepttac" element={<AcceptTacAndPs role="consultant" acceptedValidTac={false} acceptedValidPs={true} />} />
          <Route path="/consultant/acceptps" element={<AcceptTacAndPs role="consultant" acceptedValidTac={true} acceptedValidPs={false} />} />

          <Route path="/admin/accepttacandps" element={<AcceptTacAndPs role="admin" acceptedValidTac={false} acceptedValidPs={false} />} />
          <Route path="/admin/accepttac" element={<AcceptTacAndPs role="admin" acceptedValidTac={false} acceptedValidPs={true} />} />
          <Route path="/admin/acceptps" element={<AcceptTacAndPs role="admin" acceptedValidTac={true} acceptedValidPs={false} />} />
        </Route>

        <Route element={<RequireTacAndPsAuth allowedRoles={[Roles.Customer]} navigationPath="/customerlogin" />}>
          <Route path="/customer/accepttacandps" element={<AcceptTacAndPs role="customer" acceptedValidTac={false} acceptedValidPs={false} />} />
          <Route path="/customer/accepttac" element={<AcceptTacAndPs role="customer" acceptedValidTac={false} acceptedValidPs={true} />} />
          <Route path="/customer/acceptps" element={<AcceptTacAndPs role="customer" acceptedValidTac={true} acceptedValidPs={false} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
