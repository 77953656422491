import axios from '../api/axios';
import useAuth from './useAuth';
import { encryptStringData } from '../util/encryptUtil';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth({
            role: response.data.role, accesstoken: encryptStringData(response.data.accesstoken, process.env.REACT_APP_ENCRYPT_ACCESSTOKEN_SALT), acceptedTacAndPs: response.data.acceptedTacAndPs
        });
        return response.data.accesstoken;
    }
    return refresh;
};

export default useRefreshToken;
