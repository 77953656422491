//Technology
import { useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../api/axios";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export default function ConfirmEmail() {
  const errRef = useRef(); //avoids rerendering
  const param = useParams();

  //api url paths
  const confirmEmail_URL = "/confirmemail";

  //useStates
  const [errMsg, setErrMsg] = useState("");
  const [rspMsg, setRspMsg] = useState("");
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await axios
      .post(confirmEmail_URL, JSON.stringify({ token: param.token }), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        setRspMsg(response.data.message);
        setIsLoading(false);
        setErrMsg("");
      })
      .catch((error) => {
        setRspMsg("");
        setErrMsg(error?.response?.data?.error);
        setIsLoading(false);
        errRef.current.focus();
      });
  };

  async function handleDownloadTac() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadTaC();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadPrivacyStatements();
      setIsCurrentlyProcessing(false);
    }
    catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  return (
    <div className="bodyConfirmEmail">
      <header className="headerConfirmEmail">
        <img src={img1} className="CElogoB3" alt="" />
      </header>
      <div className="CEcontentContainer">
        <div>
          <div className="CEpositionElements">
            <h1 className="CEheadingTwo">E-Mail Verifizierung</h1>
          </div>
          <p className="pResetPassword">Klicke auf den Button, um deine E-Mail zu verifizieren.</p>
          <div className="CEmessages">
            <p ref={errRef} className={errMsg ? "CEerrmsgConfirm" : "CErspMsgConfirm"}>
              {errMsg || rspMsg}
            </p>
          </div>
          <div className="CEcontainerBtnAndBackToLogin">
            <form onSubmit={handleSubmit}>
              <div className="CEpositionLoadingSpinner">
                <button className="btnConfirmEmail">{isLoading ? <LoadingSpinner /> : "E-Mail verifizieren"}</button>
              </div>
            </form>
            <p className="registerHere">Zurück zur <Link to="/customerlogin" className="hoverLink">Anmeldung</Link></p>
          </div>
        </div>
      </div>
      <footer className="footerConfirmEmail">
        <div className="CElinkArea">
          <div className="CEfooterItem">
            <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
          </div>
          <div className="CEfooterItem">
            <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
          </div>
          <div className="CEfooterItem">
            <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
          </div>
        </div>
      </footer>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
      {
        isCurrentlyProcessing &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
    </div>
  );

}
