//Components
import { CustomerList } from "../../components/EmployeeArea/CustomerList";
import Idle from "../../components/Auth/Idle";
//Functions
import getNavBarDependingOnRole from "../../functions/NavbarFunctions";

export default function Customeroverview(props) {
  return (
    <div className="bodyOfEmployeeAreaPages">
      {getNavBarDependingOnRole(props.role)}
      <div className="headingAreaCustomerOverview">
        <h1 className="headingCustomerOverview">Kund:innen</h1>
      </div>
      <div className="tableAreaCustomerOverview">
        <CustomerList></ CustomerList>
      </div>
      <Idle isEmployee={true}/>
    </div >
  );
}