//Technology
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Auth
import useAuth from "../../hooks/useAuth";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import FormInput from "../../components/Inputs/FormInput";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
import img2 from "../../assets/Auth/Wireframe_Mockup_Login_Registrierung.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
import { encryptStringData } from "../../util/encryptUtil";

export default function CustomerLogin() {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();
  const errRef = useRef(); //avoids rerendering
  const userRef = useRef(); //avoids rerendering
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  //api url paths
  const LOGIN_URL = "/customerlogin";
  const USERBUSINESSPLANS_URL = "/userbusinessplans";

  //navigation routes
  const HOMEFIRSTVISITROUTE = "/HomeFirstVisit";
  const BPOVERVIEWROUTE = "/businessplanoverview";

  // Array with input properties
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "name@example.de",
      label: "E-Mail-Adresse",
    },

    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Passwort",
      label: "Passwort",
    },
  ];

  //useStates
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  // sets an error message
  useEffect(() => {
    setErrMsg("");
  }, [values.email, values.password]);

  //request to server and error handling
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoginLoading(true);
    await axios
      .post(
        LOGIN_URL,
        JSON.stringify({ email: values.email, password: values.password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(async (response) => {
        setAuth({ role: response.data.role, accesstoken: encryptStringData(response.data.accesstoken, process.env.REACT_APP_ENCRYPT_ACCESSTOKEN_SALT), acceptedTacAndPs: response.data.acceptedTacAndPs });
        setValues({
          email: "",
          password: "",
        });

        if (!response.data.validTacAccepted && !response.data.validPsAccepted) {
          navigate("/customer/accepttacandps");
        } else if (!response.data.validTacAccepted && response.data.validPsAccepted) {
          navigate("/customer/accepttac");
        } else if (response.data.validTacAccepted && !response.data.validPsAccepted) {
          navigate("/customer/acceptps");
        } else {
          if (from) {
            navigate(from, { replace: true });
          } else {
            await axiosPrivate
              .get(USERBUSINESSPLANS_URL,
                {
                  headers: {
                    'Authorization': `Bearer ${response.data.accesstoken}`
                  }
                })
              .then((response) => {
                const userbusinessplans = response?.data;

                if (userbusinessplans.businessplans.length === 0) {
                  navigate(HOMEFIRSTVISITROUTE);
                } else {
                  navigate(BPOVERVIEWROUTE + '/' + userbusinessplans.businessplans[0]);
                }
              })
              .catch((error) => {
                setErrMsg(error.response.data.error);
                setIsLoginLoading(false);
                errRef.current.focus();
              });
          }
        }
      })
      .catch((error) => {
        setErrMsg(error?.response?.data?.error);
        setIsLoginLoading(false);
        errRef.current.focus();
      });
  };

  //changes values of input fields
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleDownloadTac() {
    try {
      await downloadTaC();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    try {
      await downloadPrivacyStatements();
    } catch (error) {
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  function getLinkPart() {
    return (
      <>
        <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
        <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
        <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
      </>
    )
  }

  return (
    <div className="bodyCustomerLogin">
      <div className="containerForLeft">
        <div className="containerForHeadingAndParagraph">
          <h1 className="headingOne">Erstelle deinen Businessplan!</h1>
          <p className="paragraphOne">
            Zu jeder Unternehmensgründung gehört auch ein Businessplan.{" "}
            <br /> Bei uns hast du die Möglichkeit einfach aber effektiv deine Gründungsidee zu verwirklichen!
          </p>
        </div>

        <img src={img2} className="imageOfApp" alt="" />
        <div className="containerForParagraphs">
          {getLinkPart()}
        </div>
      </div>
      <div className="containerForLogin">
        <div className="customerLogin">
          <img src={img1} className="logoB3" alt="" />
          <form onSubmit={handleSubmit}>
            <h1 className="headingTwo">Anmelden</h1>
            {inputs.map((input) => (
              <FormInput
                refs={userRef}
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <p
              className="forgotYourPassword"
              onClick={() => {
                navigate("/customerlogin/forgotpassword");
              }}
            >
              Passwort vergessen
            </p>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>
              {errMsg}
            </p>

            <div className="containerBtnAndRegister">
              <button className="btnAnmelden">{isLoginLoading ? <LoadingSpinner /> : "Anmelden"}</button>
              <p className="registerHere">Du hast noch kein Konto? Hier <Link to="/Signup" className="hoverLink">Registrieren</Link>
              </p>
            </div>
            <div className="customerLoginLinkPartResponsive">
              {getLinkPart()}
            </div>
          </form>
        </div>
      </div>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
    </div>
  );
}