//Technology
import { useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../api/axios";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export default function ActivateAfterLock() {
  const errRef = useRef(); //avoids rerendering
  const param = useParams();

  //api url paths
  const activateAfterLock_URL = "/activateaccountafterlocking";

  //useStates
  const [errMsg, setErrMsg] = useState("");
  const [rspMsg, setRspMsg] = useState("");
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    await axios
      .post(activateAfterLock_URL, JSON.stringify({ token: param.token }), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        setRspMsg(response.data.message);
        setIsLoading(false);
        setErrMsg("");
      })
      .catch((error) => {
        setRspMsg("");
        setErrMsg(error?.response?.data?.error);
        setIsLoading(false);
        errRef.current.focus();
      });
  };

  async function handleDownloadTac() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadTaC();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadPrivacyStatements();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  return (
    <div className="bodyActivateAfterLock">
      <header className="headerActivateAfterLock">
        <img src={img1} className="AALlogoB3" alt="" />
      </header>
      <div className="AALcontentContainer">
        <div>
          <div className="AALpositionElements">
            <h1 className="AALheadingTwo">Konto freischalten</h1>
          </div>
          <p className="pResetPassword">Klicke auf den Button, um dein Konto zu entsperren.</p>
          <div className="AALmessages">
            <p ref={errRef} className={errMsg ? "AALerrmsgConfirm" : "AALrspMsgConfirm"}>
              {errMsg || rspMsg}
            </p>
          </div>
          <div className="AALcontainerBtnAndBackToLogin">
            <form onSubmit={handleSubmit}>
              <div className="AALpositionLoadingSpinner">
                <button className="btnActivateAfterLock">{isLoading ? <LoadingSpinner /> : "Konto freischalten"}</button>
              </div>
            </form>
            <p className="registerHere">Zurück zur <Link to="/customerlogin" className="hoverLink">Anmeldung</Link></p>
          </div>
        </div>
      </div>
      <footer className="footerActivateAfterLock">
        <div className="AALlinkArea">
          <div className="AALfooterItem">
            <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
          </div>
          <div className="AALfooterItem">
            <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
          </div>
          <div className="AALfooterItem">
            <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
          </div>
        </div>
      </footer>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
      {
        isCurrentlyProcessing &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
    </div>
  );
}
