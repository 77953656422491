import { NavLink } from "react-router-dom";
import img1 from "../assets/Logos/B3-Logo_300dpi_noheading.svg";
import Navbar from "../components/Navigation/Navbar";

export default function getNavBarDependingOnRole(role) {
    return (
        <div>
            <div className="headernavbar">
                {getLogoNavLinkDependingOnRole(role)}
                {getNavBarMenuDependingOnRole(role)}
                <Navbar isEmployeeNavbar={true}></Navbar>
            </div>
        </div>
    );
};

function getLogoNavLinkDependingOnRole(role) {
    if (role === "admin") {
        return (
            <NavLink to="/admin/customerlist">
                <img src={img1} className="logoB3ConsulantOverview" alt="Logo" />
            </NavLink>
        );
    } else if (role === "consultant") {
        return (
            <NavLink to="/consultant/customerlist">
                <img src={img1} className="logoB3ConsulantOverview" alt="Logo" />
            </NavLink>
        );
    }
}

function getNavBarMenuDependingOnRole(role) {
    if (role === "admin") {
        return (
            <div className="headerNavigation">
                <div className="divNavbar">
                    <NavLink to="/admin/customerlist">
                        <button className="btnNavbar">Kund:innen</button>
                    </NavLink>
                </div>
                <div className="divNavbar">
                    <NavLink to="/admin/employees">
                        <button className="btnNavbar">Mitarbeiter:innen</button>
                    </NavLink>
                </div>
                <div className="divNavbar">
                    <NavLink to="/admin/tacandps">
                        <button className="btnNavbar">AGB und Datenschutz</button>
                    </NavLink>
                </div>
                <div className="divNavbar">
                    <NavLink to="/admin/tabletemplatesoverview">
                        <button className="btnNavbar">Tabellen</button>
                    </NavLink>
                </div>
                <div className="divNavbar">
                    <NavLink to="/admin/businessplanstructure">
                        <button className="btnNavbar">BP-Struktur</button>
                    </NavLink>
                </div>
            </div>
        );
    } else if (role === "consultant") {
        return (
            <div className="headerNavigation">
                <div className="divNavbar">
                    <NavLink to="/consultant/customerlist">
                        <button className="btnNavbar">Kund:innen</button>
                    </NavLink>
                </div>
                <div className="divNavbar">
                    <NavLink to="/consultant/tabletemplatesoverview">
                        <button className="btnNavbar">Tabellen</button>
                    </NavLink>
                </div>
            </div>
        );
    }
}