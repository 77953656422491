//Technology
import React, { useState, useRef, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import AlertModal from "../../components/Modals/AlertModal";
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Modal from "../../components/Modals/Modal";
import SuccessAlert from "../../components/Modals/SuccessAlert";
//Functions
import getNavBarDependingOnRole from "../../functions/NavbarFunctions";

function Employees() {
    const axiosPrivate = useAxiosPrivate();
    const errRef = useRef();

    //useStates for data
    const [consultantMails, setConsultantMails] = useState([]);
    const [adminMails, setAdminMails] = useState([]);

    //useStates for adding employee
    const [showConsultant, setShowConsultant] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);
    const [route, setRoute] = useState('');
    const [email, setEmail] = useState("");
    const [isConsultant, setIsConsultant] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    //useStates for success alert
    const [showSuccess, setShowSuccess] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState("");

    //useStates for deletion of employee
    const [showDelete, setShowDelete] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [userName, setUsername] = useState("");
    const [deleteIndex, setDeleteIndex] = useState(0);
    const [deleteRoute, setDeleteRoute] = useState("");

    //useStates for fail alert
    const [errorMessageFailAlertAdmin, setErrorMessageFailAlertAdmin] = useState("");
    const [errorMessageFailAlertConsultant, setErrorMessageFailAlertConsultant] = useState("");
    const [errorMessageFailAlertDelete, setErrorMessageFailAlertDelete] = useState("");
    const [failAlertVisibleAdmin, setFailAlertVisibleAdmin] = useState(false);
    const [failAlertVisibleConsultant, setFailAlertVisibleConsultant] = useState(false);
    const [failAlertVisibleDelete, setFailAlertVisibleDelete] = useState(false);

    //useStates for loading process
    const [isLoadingConsultant, setIsLoadingConsultant] = useState(true);
    const [isLoadingAdmin, setIsLoadingAdmin] = useState(true);
    const [getUserSuccessFull, setGetUserSuccessFull] = useState(false);
    const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "email") {
            setEmail(value);
        }
    }

    async function deleteUser(index, routeParam) {
        setIsCurrentlyProcessing(true);
        let user;
        if (routeParam === '/deleteadmin') {
            user = adminMails[index];
        } else {
            user = consultantMails[index];
        }
        await axiosPrivate.delete(
            routeParam,
            {
                data: {
                    email: user
                }
            }
        )
            .then((response) => {
                if (routeParam === '/deleteadmin') {
                    getAdmins();
                } else {
                    getConsultants();
                }
                setShowSuccess(true);
                setPopUpMessage(response.data.message);
                setTimeout(() => { setShowSuccess(false); }, 3000);
            })
            .catch((error) => {
                setErrorMessageFailAlertDelete(error.response.data.error);
                setFailAlertVisibleDelete(true);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisibleDelete(false) }, 3000);
            });
    }

    const getConsultantsAndAdmins = async () => {
        await getConsultants();
        await getAdmins();
    }

    const getConsultants = async () => {
        await axiosPrivate
            .get('/consultants')
            .then((response) => {
                setConsultantMails(response.data.consultants);
                setGetUserSuccessFull(true);
                setIsLoadingConsultant(false);
                setIsCurrentlyProcessing(false);
            }).catch((error) => {
                setErrorMessageFailAlertConsultant(error.response.data.error);
                setFailAlertVisibleConsultant(true);
                setIsLoadingConsultant(false);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisibleConsultant(false) }, 3000)
            });
    }

    const getAdmins = async () => {
        await axiosPrivate
            .get('/admins')
            .then((response) => {
                setAdminMails(response.data.admins);
                setGetUserSuccessFull(true);
                setIsLoadingAdmin(false);
                setIsCurrentlyProcessing(false);
            }).catch((error) => {
                setErrorMessageFailAlertAdmin(error.response.data.error);
                setFailAlertVisibleAdmin(true);
                setIsLoadingAdmin(false);
                setIsCurrentlyProcessing(false);
                setTimeout(() => { setFailAlertVisibleAdmin(false) }, 3000)
            });
    }

    useEffect(() => {
        getConsultantsAndAdmins();
    }, []);

    const changeToAdmin = async () => {
        setIsCurrentlyProcessing(true);
        let formControl = document.querySelector('.form-control');
        await axiosPrivate.post(
            '/changetoadmin', JSON.stringify({
                "email": email
            })
        )
            .then((response) => {
                setErrMsg("")
                formControl.style = "border-color:#198754 !important";
                formControl.value = "";
                setShowAdmin(false);
                getConsultantsAndAdmins();
                setIsConsultant(false);
                setShowSuccess(true);
                setPopUpMessage(response.data.message);
                setTimeout(() => { setShowSuccess(false); }, 3000);
            })
            .catch((error) => {
                setErrMsg(error?.response?.data?.error);
                errRef.current.focus();
                formControl.style = "border-color:#DD6243 !important";
                setIsCurrentlyProcessing(false);
            })
    }

    const changeToBerater = async () => {
        setIsCurrentlyProcessing(true);
        let formControl = document.querySelector('.form-control');
        await axiosPrivate.post(
            '/changetoconsultant', JSON.stringify({
                "email": email
            })
        )
            .then((response) => {
                setErrMsg("")
                formControl.style = "border-color:#198754 !important";
                formControl.value = "";
                setShowConsultant(false)
                getConsultantsAndAdmins();
                setIsAdmin(false);
                setShowSuccess(true);
                setPopUpMessage(response.data.message);
                setTimeout(() => { setShowSuccess(false); }, 3000);
            })
            .catch((error) => {
                setErrMsg(error?.response?.data?.error);
                errRef.current.focus();
                formControl.style = "border-color:#DD6243 !important";
                setIsCurrentlyProcessing(false);
            })
    }

    const addNewEmployee = async () => {
        setIsCurrentlyProcessing(true);
        let formControl = document.querySelector('.form-control');
        await axiosPrivate.post(
            route, JSON.stringify({
                "email": email
            })
        )
            .then((response) => {
                setErrMsg("");
                formControl.style = "border-color:#198754 !important";
                formControl.value = "";
                if (route === '/addadmin') {
                    setShowAdmin(false)
                    getAdmins();
                } else {
                    setShowConsultant(false);
                    getConsultants();
                }
                setIsConsultant(false);
                setIsAdmin(false);
                setShowSuccess(true);
                setPopUpMessage(response.data.message);
                setTimeout(() => { setShowSuccess(false); }, 3000)
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    if (error.response.data.isConsultant) {
                        setIsConsultant(true);
                        setErrMsg("");
                    } else if (error.response.data.isAdmin) {
                        setIsAdmin(true);
                        setErrMsg("");
                    } else {
                        setErrMsg(error?.response?.data?.error);
                    }
                    errRef.current.focus();
                    setIsCurrentlyProcessing(false);
                }
            })
    };

    return (
        <div className="bodyOfEmployeesPage">
            {getNavBarDependingOnRole('admin')}
            <div>
                <div className='listen-header-container'>
                    <h1 className="headingEmployees">Mitarbeiter:innen</h1>
                    <div className="listen-btn-container">
                        <button className='listen-btn' onClick={() => { setRoute('/addconsultant'); setEmail(""); setShowConsultant(true); }}>+ Neue Berater:in</button>
                        <Modal title="Berater:in hinzufügen" name="Berater:in hinzufügen" button="Abbrechen" onClose={() => { setShowConsultant(false); setErrMsg(""); setEmail(""); setIsAdmin(false) }} show={showConsultant} onSubmit={addNewEmployee}>
                            <p>Bitte trage die E-Mail-Adresse für eine neue Berater:in ein:</p>
                            <label htmlFor="email">E-Mail-Adresse</label>
                            <input className="form-control" type="text" label="E-Mail-Adresse" id="email" value={email} onChange={(e) => handleInputChange(e)} required></input>
                            {
                                isAdmin &&
                                <div>
                                    <p className="email-error-text">Diese E-Mail-Adresse ist bereits als Admin zugelassen. Rolle dieser E-Mail-Adresse zu <b>Berater:in</b> ändern?</p>
                                    <div onClick={() => { changeToBerater() }}>
                                        <p className="email-error">E-Mail-Adresse zu Berater:in ändern</p>
                                    </div>
                                </div>
                            }
                            <p ref={errRef} className={errMsg ? "email-error-text" : "offscreen"}>{errMsg}</p>
                        </Modal>
                        <button className='listen-btn' onClick={() => { setRoute('/addadmin'); setEmail(""); setShowAdmin(true); }}>+ Neuer Admin</button>
                        <Modal title="Admin hinzufügen" name="Admin hinzufügen" button="Abbrechen" onClose={() => { setShowAdmin(false); setErrMsg(""); setEmail(""); setIsConsultant(false) }} show={showAdmin} onSubmit={addNewEmployee}>
                            <p>Bitte trage die E-Mail-Adresse für einen neuen Admin ein:</p>
                            <label htmlFor="email">E-Mail-Adresse</label>
                            <input className="form-control" type="text" label="E-Mail-Adresse" id="email" value={email} onChange={(e) => handleInputChange(e)} ></input>
                            {
                                isConsultant &&
                                <div>
                                    <p className="email-error-text">Diese E-Mail-Adresse ist bereits als Berater:in zugelassen. Rolle dieser E-Mail-Adresse zu <b>Admin</b> ändern?</p>
                                    <div onClick={() => { changeToAdmin() }}>
                                        <p className="email-error">E-Mail-Adresse zu Admin ändern</p>
                                    </div>
                                </div>
                            }
                            <p ref={errRef} className={errMsg ? "email-error-text" : "offscreen"}>{errMsg}</p>
                        </Modal>
                    </div>
                </div>

                <div className='listen-outer-container'>
                    <div className='listen-bereich-container liste-left'>
                        <div className='listen-sub-header-container'>
                            <h1 className='listen-header'>Berater:in</h1>
                        </div>
                        <div className='listen-container-content'>
                            <div className='EHeadlineContainer'>
                                <p className='listen-subheader'>E-Mail-Adresse</p>
                            </div>
                            {
                                isLoadingConsultant &&
                                <div className="overflow-liste">
                                    <LoadingSpinner />
                                </div>
                            }
                            {
                                !isLoadingConsultant && getUserSuccessFull &&
                                <div className="overflow-liste">
                                    {consultantMails.map((item, index) => (
                                        <div key={index} className='listen-containers'>
                                            <p className='listen-texts'>{item}</p>
                                            {<div
                                                className="employee-trash-btn"
                                                onClick={() => {
                                                    setDeleteIndex(index);
                                                    setDeleteRoute("/deleteconsultant");
                                                    setConfirmMessage("Berater");
                                                    setUsername(item);
                                                    setShowDelete(true);
                                                }} />}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>

                    <div className='listen-bereich-container liste-right'>
                        <div className='listen-sub-header-container'>
                            <h1 className='listen-header'>Admin</h1>
                        </div>
                        <div className='listen-container-content'>
                            <div className='EHeadlineContainer'>
                                <p className='listen-subheader'>E-Mail-Adresse</p>
                            </div>
                            {
                                isLoadingAdmin &&
                                <div className="overflow-liste">
                                    <LoadingSpinner />
                                </div>
                            }
                            {
                                !isLoadingAdmin && getUserSuccessFull &&
                                <div className="overflow-liste">
                                    {adminMails.map((item, index) => (
                                        <div key={index} className='listen-containers'>
                                            <p className='listen-texts'>{item}</p>
                                            {<div className="employee-trash-btn"
                                                onClick={() => {
                                                    setDeleteIndex(index);
                                                    setDeleteRoute("/deleteadmin");
                                                    setConfirmMessage("Admin");
                                                    setUsername(item);
                                                    setShowDelete(true);
                                                }} />}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
            <SuccessAlert show={showSuccess} onClose={() => setShowSuccess(false)}>
                <p className="rspMessageSuccess">{popUpMessage}</p>
            </SuccessAlert>
            <AlertModal
                title={confirmMessage + " löschen?"}
                name={"Ja, " + confirmMessage + " löschen"}
                button="Abbrechen"
                onClose={() =>
                    setShowDelete(false)
                }
                show={showDelete}
                onSubmit={() => { deleteUser(deleteIndex, deleteRoute); setShowDelete(false); }}
            >
                <p>
                    Du bist dabei den {confirmMessage} <b> {userName} </b> zu löschen... Alle Daten werden unwiderruflich aus der Datenbank entfernt.
                </p>
            </AlertModal>
            <FailAlert show={failAlertVisibleAdmin} onClose={() => setFailAlertVisibleAdmin(false)}>{<p className="rspMessageError">{errorMessageFailAlertAdmin}</p>}</FailAlert>
            <FailAlert show={failAlertVisibleConsultant} onClose={() => setFailAlertVisibleConsultant(false)}>{<p className="rspMessageError">{errorMessageFailAlertConsultant}</p>}</FailAlert>
            <FailAlert show={failAlertVisibleDelete} onClose={() => setFailAlertVisibleDelete(false)}>{<p className="rspMessageError">{errorMessageFailAlertDelete}</p>}</FailAlert>
            {
                (!isLoadingConsultant || !isLoadingAdmin) && isCurrentlyProcessing &&
                <div className="saveLoadingSpinnerArea">
                    <LoadingSpinner />
                </div>
            }
            <Idle isEmployee={true} />
        </div >
    );
}

export default Employees;