import React from "react";

/* Displays a notification for the user when a taken action was failed. */

const FailAlert = props => {
    if (!props.show) {
        return null
    }

    return (
        <div className="fail-container" role="alert">
            <div className="fail-body">
                <div className="fail_check_icon" alt="fail"></div>
                <div className="fail-content">{props.children}</div>
                <div className="fail-close-btn" onClick={props.onClose}></div>
            </div>
        </div>
    )
}

export default FailAlert