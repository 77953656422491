//Technology
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Navbar from "../../components/Navigation/Navbar";

const FirstVisit = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //default values
  const bpDefaultName = "Businessplan 1";

  //api url paths
  const Create_URL = "/createbusinessplan";

  //useStates
  const [showFail, setShowFail] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [isBusinessplanCreationLoading, setIsBusinessplanCreationLoading] = useState(false);
  const [bpName, setBpName] = useState("");

  /**
   * Saves the name of the businessplan 
   * @param {*} event 
   */
  function changeName(event) {
    setBpName(event.target.value);
  }

  /**
   * Checks for empty name, creates businessplan and navigates to next page.
   * @param {*} e 
   */
  const handleSubmit = async (e) => {
    setIsBusinessplanCreationLoading(true);
    e.preventDefault();
    await axiosPrivate
      .post(Create_URL, JSON.stringify({ title: bpName === "" ? bpDefaultName : bpName }))
      .then((response) => {
        navigate("/businessplanoverview/" + response.data.businessplanid);
      })
      .catch((error) => {
        setIsBusinessplanCreationLoading(false);
        setShowFail(true);
        setPopUpMessage(error.response.data.error);
        setTimeout(() => {
          setShowFail(false);
          setPopUpMessage("");
        }, 3000);
      });
  }

  /**
   * Output.
   */
  return (
    <div className="Dashboard" >
      <div className="FVInhalt">
        <div className="Navigation">
          <div className="Navigationplace"></div>
          <div className="WNavbar">
            <Navbar />
          </div>
        </div>
        <div className="FVOnboarding">
          <h1 className="FVH1">Businessplan erstellen</h1>
          <p className="FVOnboardingText">Schön, dass du überlegst dich selbständig zu machen!
            Du hast dir bestimmt schon viele Gedanken zu deinem Geschäftsmodell gemacht?! Wir helfen dir deine Gedanken zu strukturieren. <br></br>
            Es ist ganz einfach, durch die Beantwortung nachfolgender Fragen erstellst du deinen individuellen und professionellen Businessplan.
            <br />
            <br />
            Bevor du richtig loslegst, solltest du deinem Plan einen Titel geben:
          </p>
          <div >
            <form className="FVEingabe" onSubmit={handleSubmit}>
              <input type="text" className="FVBusinessplanName" placeholder="Businessplan 1" onChange={changeName} value={bpName}></input>
              <button className="FVBusinessplanErstellen">{isBusinessplanCreationLoading ? <LoadingSpinner /> : "Businessplan erstellen"}</button>
            </form>
          </div>
        </div>
      </div>
      <div className="FVFirstVisitMockup">
        <div className="FVBilderrahmen">
          <div className="FVBild" >
          </div>
        </div>
      </div>
      <FailAlert show={showFail} onClose={() => { setShowFail(false); setPopUpMessage(""); }}>
        <p className="rspMessageError">{popUpMessage}</p>
      </FailAlert>
      <Idle />
    </div>
  );
};

export default FirstVisit;
