//Technology
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Auth
import useAuth from "../../hooks/useAuth";
//Components
import AlertModal from "../../components/Modals/AlertModal";
import FailAlert from "../../components/Modals/FailAlert";
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Modal from "../../components/Modals/Modal";
import Navbar from "../../components/Navigation/Navbar";
import SuccessAlert from "../../components/Modals/SuccessAlert";

export default function AccountSettings(props) {
    const { setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const errRef = useRef();
    const navigate = useNavigate();

    //useStates
    const [show, setShow] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [success, setSuccess] = useState(false);
    const [information, setInformation] = useState({
        salutation: "",
        firstName: "",
        secondName: "",
    });
    const [errMsg, setErrMsg] = useState("");
    const [errMsgDelete, setErrMsgDelete] = useState("");
    const [errMsgPassword, setErrMsgPassword] = useState("");
    const [rspSuccess, setRspSuccess] = useState();
    const [errorMessageNoUser, setErrorMessageNoUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [getUserSuccessFull, setGetUserSuccessFull] = useState(false);
    const [getUserFailed, setGetUserFailed] = useState(false);
    const [noUserFailAlertVisible, setNoUserFailAlertVisible] = useState(false);
    const [values, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        passwordRepeat: "",
    });
    const [isLockedByWrongInput, setIsLockedByWrongInput] = useState(false);
    const [isLoadingSaveChanges, setIsLoadingSaveChanges] = useState(false);
    const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

    //api url paths
    const GETUSER_URL = "/user";
    const UPDATEUSER_URL = "/updateuser";
    const CHANGEPASSWORD_URL = "/changepassword";
    const DELETEUSER_URL = "/deleteuser";
    const LOGOUT_URL = "/logout";

    //navigation routes
    const customerloginRoute = "/customerlogin";
    const employeeloginRoute = "/employeelogin";

    useEffect(() => {
        getUserData();
    }, []);

    const handleModalOnClose = async () => {
        setShow(false);
        setErrMsgPassword("");
        setValues({
            oldPassword: "",
            newPassword: "",
            passwordRepeat: "",
        });
        if (isLockedByWrongInput === true) {
            await logout();
        }
    }

    const handleAlertModalOnClose = async () => {
        setIsActive(false);
        setErrMsgDelete("");
    }

    const logout = async () => {
        await axios
            .get(LOGOUT_URL, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            })
            .then((response) => { })
            .catch((error) => { });
        setAuth({});
        navigate(props.isEmployee ? employeeloginRoute : customerloginRoute);
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const onChangeInformation = (e) => {
        setInformation({ ...information, [e.target.name]: e.target.value });
    }

    const getUserData = async () => {
        await axiosPrivate
            .get(GETUSER_URL)
            .then((response) => {
                setInformation(response?.data);
                setGetUserSuccessFull(true);
                setIsLoading(false);
            })
            .catch((error) => {
                setErrorMessageNoUser(error.response.data.error);
                setGetUserFailed(true);
                setNoUserFailAlertVisible(true);
                setIsLoading(false);
                setTimeout(() => { setNoUserFailAlertVisible(false) }, 3000);
            });
    };

    const updateInformation = async (e) => {
        setIsLoadingSaveChanges(true);
        try {
            const response = await axiosPrivate.post(
                UPDATEUSER_URL,
                JSON.stringify(information)
            )
            setSuccess(true);
            setTimeout(() => { setSuccess(false); setRspSuccess(""); }, 3000);
            setRspSuccess(response?.data.message);
            setErrMsg("");
        } catch (error) {
            setErrMsg(error?.response?.data?.error);
        }
        setIsLoadingSaveChanges(false);
    }

    const setNewPassword = async (e) => {
        if (!isLockedByWrongInput) {
            setIsCurrentlyProcessing(true);
            try {
                const response = await axiosPrivate
                    .post(
                        CHANGEPASSWORD_URL,
                        JSON.stringify({ oldPassword: values.oldPassword, password: values.newPassword, repeatPassword: values.passwordRepeat })
                    )
                setRspSuccess(response?.data.message);
                setErrMsgPassword("");
                setSuccess(true);
                setTimeout(() => { setSuccess(false); setRspSuccess(""); }, 3000);
                setShow(false);
                setValues({
                    oldPassword: "",
                    newPassword: "",
                    passwordRepeat: "",
                });
            } catch (error) {
                if (error?.response?.data?.lockedByWrongInput === true) {
                    setIsLockedByWrongInput(true);
                }
                setErrMsgPassword(error?.response?.data?.error);
                errRef.current.focus();
            }
            setIsCurrentlyProcessing(false);
        }
    }

    const deleteUser = async () => {
        setIsCurrentlyProcessing(true);
        try {
            await axiosPrivate
                .delete(
                    DELETEUSER_URL
                )
            setIsCurrentlyProcessing(false);
            setAuth({});
            navigate(props.isEmployee ? employeeloginRoute : customerloginRoute);
        } catch (error) {
            setIsCurrentlyProcessing(false);
            setErrMsgDelete(error?.response?.data?.error);
        };
    }

    const handleBlur = (e) => {
        setInformation({ ...information, [e.target.name]: e.target.value });
    }

    return (
        /* Main Container for all Elements */
        <div className="bodyAccountSettings">
            {/* Container for Header */}
            <header className="ACHeader">
                <div className="chevron-back" alt="back" onClick={() => { navigate(-1); }}></div>
                <div className="ACTitleContainer"><h1 className="ACTitle"> Kontoeinstellungen</h1></div>
                <div className='ACPositioningNavbar'>
                    <Navbar isEmployeeNavbar={props.isEmployee} />
                </div>
            </header>
            {
                isLoading &&
                <div className="ACContainer">
                    <LoadingSpinner />
                </div>
            }
            {/* Container for Card */}
            {!isLoading && getUserSuccessFull &&
                <div className="ACContainer">
                    {/* Container for User Information */}
                    <div className="ACUserInformationContainer">
                        <h2 className="ACFont">Persönliche Angaben</h2>
                        <div className="ACUserInformationContent">
                            <div className="ACInputFields">
                                <label htmlFor="salutation">Anrede</label> <br></br>
                                <select
                                    className="ACAnredeSelect" name="salutation" id="salutation" value={information.salutation} onChange={onChangeInformation}>
                                    <option id="empty">-</option>
                                    <option id="herr">Herr</option>
                                    <option id="frau">Frau</option>
                                    <option id="divers">Divers</option>
                                </select>
                            </div>
                            <div className="ACInputFields">
                                <label htmlFor="firstName">Vorname</label> <br></br>
                                <input type="text"
                                    name="firstName"
                                    id="userName"
                                    value={information.firstName}
                                    onBlur={handleBlur}
                                    onChange={onChangeInformation}>
                                </input>
                            </div>
                            <div className="ACInputFields">
                                <label htmlFor="secondName">Name</label> <br></br>
                                <input
                                    type="text"
                                    name="secondName"
                                    id="secondName"
                                    value={information.secondName}
                                    onBlur={handleBlur}
                                    onChange={onChangeInformation}>
                                </input>
                            </div>
                        </div>
                        <div className="ACpositionErrorMsg">
                            <p ref={errRef} className="errorMsg"> {errMsg} </p>
                        </div>
                    </div>
                    {/* Container for password change */}
                    <div className="ACPassword">
                        <h2 className="ACFont">Passwort ändern</h2>
                        <button className="ACPasswordChange" onClick={() => setShow(true)}> Passwort ändern</button>
                        <Modal title="Passwort ändern" name="Passwort ändern" button="Abbrechen" onClose={handleModalOnClose} onSubmit={setNewPassword} show={show}>
                            <form>
                                <label>Altes Passwort</label>
                                <input className="ACField" type="password" id="foldPassword" name="oldPassword" onChange={onChange} ></input> <br></br>
                                <label>Neues Passwort</label>
                                <input className="ACField" type="password" id="fpasswordNew" name="newPassword" onChange={onChange}></input>
                                <br></br>
                                <label>Neues Passwort wiederholen</label>
                                <input className="ACField" type="password" id="fpasswordRepeat" name="passwordRepeat" onChange={onChange}></input>
                            </form>
                            <div className="displayMsg">
                                <p ref={errRef} className={errMsgPassword ? "errPassword" : "offscreen"}> {errMsgPassword} </p>
                            </div>
                        </Modal>
                    </div>
                    {/* Container for deleting user */}
                    <div className="ACDeleteUserContainer">
                        <h2 className="ACFont">Lösche dein Konto</h2>
                        <p className="ACDeleteUser"> Dein Konto kann gelöscht, aber nicht widerrufen werden. Bitte vergewissere dich vorher, ob du dies wirklich möchtest.
                            <button className="ACDeleteUserButton" onClick={() => setIsActive(true)}>
                                Konto löschen...
                            </button>
                            <AlertModal id="box" title="Konto löschen?" button="Nein, Konto behalten" name="Ja, Konto löschen" onSubmit={deleteUser} onClose={handleAlertModalOnClose} show={isActive}>
                                <p> Du bist dabei dein Konto zu löschen... Alle deine Daten werden unwiderruflich aus unserer Datenbank entfernt. <br></br> <br></br>
                                    Wenn du dein Konto löschst, gehen dein Profil und alle dazugehörigen Daten dauerhaft verloren.</p>
                                <p ref={errRef} className={errMsgDelete ? "errorUserDelete" : "offscreen"}> {errMsgDelete} </p>
                            </AlertModal>
                        </p>
                    </div>
                    {/* Container for actions */}
                    <div className="ACAction">
                        <button className="btn-cancel" onClick={() => { navigate(-1) }}> Abbrechen </button>
                        <div className="btn-save-div">
                            <button className="btn-save" onClick={updateInformation} >{isLoadingSaveChanges ? <LoadingSpinner /> : "Änderungen speichern"} </button>
                        </div>
                    </div>
                </div>
            }
            {
                !isLoading && getUserFailed &&
                <div className="ACContainer">
                    <p>Fehler beim Laden der Account-Daten.</p>
                </div>
            }
            {
                isCurrentlyProcessing &&
                <div className="saveLoadingSpinnerArea">
                    <LoadingSpinner />
                </div>
            }
            <FailAlert show={noUserFailAlertVisible} onClose={() => setNoUserFailAlertVisible(false)}>{<p className="rspMessageError">{errorMessageNoUser}</p>}</FailAlert>
            <SuccessAlert show={success} onClose={() => setSuccess(false)}  >
                <p className="rspMessageSuccess">{rspSuccess}</p>
            </SuccessAlert>
            <Idle />
        </div>
    )
}