//Technology
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import axios from "../../api/axios";
//Auth
import useAuth from "../../hooks/useAuth";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import FormInput from "../../components/Inputs/FormInput";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";
import { encryptStringData } from "../../util/encryptUtil";

export default function EmployeesLogin() {
  const { setAuth } = useAuth();
  const errRef = useRef(); //avoids rerendering
  const userRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  //api url paths
  const EMP_LOGIN_URL = "/employeelogin";

  //navigation routes
  const consultantCustomerlistPath = "/consultant/customerlist";
  const adminCustomerlistPath = "/admin/customerlist";

  // Array with input properties
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "name@example.de",
      label: "E-Mail-Adresse",
    },

    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Passwort",
      label: "Passwort",
    },
  ];

  //useStates
  const [errMsg, setErrMsg] = useState("");
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [values.email, values.password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoginLoading(true);
    await axios
      .post(
        EMP_LOGIN_URL,
        JSON.stringify({ email: values.email, password: values.password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true
        }
      )
      .then((response) => {
        const role = response.data.role;
        setAuth({ role, accesstoken: encryptStringData(response.data.accesstoken, process.env.REACT_APP_ENCRYPT_ACCESSTOKEN_SALT), acceptedTacAndPs: response.data.acceptedTacAndPs });
        setValues({
          email: "",
          password: "",
        });
        if (!response.data.validTacAccepted && !response.data.validPsAccepted) {
          if (role === "consultant") {
            navigate("/consultant/accepttacandps");
          } else if (role === "admin") {
            navigate("/admin/accepttacandps");
          }
        } else if (!response.data.validTacAccepted && response.data.validPsAccepted) {
          if (role === "consultant") {
            navigate("/consultant/accepttac");
          } else if (role === "admin") {
            navigate("/admin/accepttac");
          }
        } else if (response.data.validTacAccepted && !response.data.validPsAccepted) {
          if (role === "consultant") {
            navigate("/consultant/acceptps");
          } else if (role === "admin") {
            navigate("/admin/acceptps");
          }
        } else {
          if (from) {
            navigate(from, { replace: true });
          } else {
            if (role === "consultant") {
              navigate(consultantCustomerlistPath);
            } else if (role === "admin") {
              navigate(adminCustomerlistPath);
            }
          }
        }
      })
      .catch((error) => {
        setErrMsg(error?.response?.data?.error);
        setIsLoginLoading(false);
        errRef.current.focus();
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleDownloadTac() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadTaC();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadPrivacyStatements();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message)
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  return (
    <div className="bodyEmployeeLogin">
      <header className="headerEmployeeLogin">
        <img src={img1} className="ELlogoB3" alt="" />
      </header>
      <div className="ELcontentContainer">
        <div>
          <div className="ELpositionElements">
            <h1 className="ELheadingTwo">Anmelden</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="ELpositionInputs">
              {inputs.map((input) => (
                <FormInput
                  refs={userRef}
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
              ))}
              <p
                className="forgotYourPassword"
                onClick={() => {
                  navigate("/employeelogin/ForgotPassword");
                }}
              >
                Passwort vergessen
              </p>
            </div>
            <div className="ELmessages">
              <p ref={errRef} className="ELerrmsgReset">{errMsg}</p>
            </div>
            <div className="ELcontainerBtnAndBackToLogin">
              <div className="ELpositionLoadingSpinner">
                <button className="btnEmployeeLogin">{isLoginLoading ? <LoadingSpinner /> : "Anmelden"}</button>
              </div>
              <p className="registerHere">Du hast noch kein Konto? Hier <Link to="/Signup" className="hoverLink">Registrieren</Link></p>
            </div>
          </form>
        </div>
      </div>
      <footer className="footerEmployeeLogin">
        <div className="ELlinkArea">
          <div className="ELfooterItem">
            <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
          </div>
          <div className="ELfooterItem">
            <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
          </div>
          <div className="ELfooterItem">
            <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
          </div>
        </div>
      </footer>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
      {
        isCurrentlyProcessing &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
    </div>
  );
}
