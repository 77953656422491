//Technology
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Auth
import useAuth from "../../hooks/useAuth";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
//Components
import Modal from '../../components/Modals/Modal';
import Idle from "../../components/Auth/Idle";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

export default function AcceptTacAndPs(props) {
    const axiosPrivate = useAxiosPrivate();
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    //useStates
    const [errMsg, setErrMsg] = useState("");
    const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

    //api url paths
    const LOGOUT_URL = "/logout";
    const USERBUSINESSPLANS_URL = "/userbusinessplans";
    const ACCEPT_TACANDPS_URL = "/accepttacandps";
    const ACCEPT_TAC_URL = "/accepttac";
    const ACCEPT_PS_URL = "/acceptps";

    //navigation routes
    const customerloginRoute = "/customerlogin";
    const employeeloginRoute = "/employeelogin";
    const consultantCustomerlistPath = "/consultant/customerlist";
    const adminCustomerlistPath = "/admin/customerlist";
    const HOMEFIRSTVISITROUTE = "/HomeFirstVisit";
    const BPOVERVIEWROUTE = "/businessplanoverview";

    const logout = async () => {
        setIsCurrentlyProcessing(true);
        await axios
            .get(LOGOUT_URL, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            })
            .then((response) => {
                setIsCurrentlyProcessing(false);
                setAuth({});
                if (props.role === "customer") {
                    navigate(customerloginRoute);
                } else {
                    navigate(employeeloginRoute);
                }
            })
            .catch((error) => {
                setIsCurrentlyProcessing(false);
                setErrMsg(error.response.data.error);
            });
    }

    async function handleDownloadTac() {
        try {
            setIsCurrentlyProcessing(true);
            await downloadTaC();
            setIsCurrentlyProcessing(false);
        } catch (error) {
            setIsCurrentlyProcessing(false);
            setErrMsg(error.message);
        }
    }

    async function handleDownloadPrivacyStatement() {
        try {
            setIsCurrentlyProcessing(true);
            await downloadPrivacyStatements();
            setIsCurrentlyProcessing(false);
        } catch (error) {
            setIsCurrentlyProcessing(false);
            setErrMsg(error.message);
        }
    }

    const acceptTacAndPs = async () => {
        setIsCurrentlyProcessing(true);
        await axiosPrivate
            .post(ACCEPT_TACANDPS_URL)
            .then((response) => {
                setIsCurrentlyProcessing(false);
                setAuth(prev => {
                    return { ...prev, acceptedTacAndPs: response.data.acceptedTacAndPs }
                });
                navigateOn();
            })
            .catch((error) => {
                setIsCurrentlyProcessing(false);
                setErrMsg(error.response.data.error);
            });
    }

    const acceptTac = async () => {
        setIsCurrentlyProcessing(true);
        await axiosPrivate
            .post(ACCEPT_TAC_URL)
            .then((response) => {
                setIsCurrentlyProcessing(false);
                setAuth(prev => {
                    return { ...prev, acceptedTacAndPs: response.data.acceptedTacAndPs }
                });
                navigateOn();
            })
            .catch((error) => {
                setIsCurrentlyProcessing(false);
                setErrMsg(error.response.data.error);
            });
    }

    const acceptPrivacyStatement = async () => {
        setIsCurrentlyProcessing(true);
        await axiosPrivate
            .post(ACCEPT_PS_URL)
            .then((response) => {
                setIsCurrentlyProcessing(false);
                setAuth(prev => {
                    return { ...prev, acceptedTacAndPs: response.data.acceptedTacAndPs }
                });
                navigateOn();
            })
            .catch((error) => {
                setIsCurrentlyProcessing(false);
                setErrMsg(error.response.data.error);
            });
    }

    const navigateOn = async () => {
        if (props.role === "admin") {
            navigate(adminCustomerlistPath);
        } else if (props.role === "consultant") {
            navigate(consultantCustomerlistPath);
        } else if (props.role === "customer") {
            setIsCurrentlyProcessing(true);
            await axiosPrivate
                .get(USERBUSINESSPLANS_URL)
                .then((response) => {
                    const userbusinessplans = response?.data;

                    setIsCurrentlyProcessing(false);
                    if (userbusinessplans.businessplans.length === 0) {
                        navigate(HOMEFIRSTVISITROUTE);
                    } else {
                        navigate(BPOVERVIEWROUTE + '/' + userbusinessplans.businessplans[0]);
                    }
                })
                .catch((error) => {
                    setIsCurrentlyProcessing(false);
                    setErrMsg(error.message);
                });
        }
    }

    return (
        <div>
            <Modal
                title={
                    !props.acceptedValidTac && !props.acceptedValidPs
                        ? "Änderungen an AGB und Datenschutz"
                        : !props.acceptedValidTac
                            ? "Änderungen an den AGB"
                            : "Änderungen am Datenschutz"
                }
                name="Akzeptieren"
                button="Ausloggen"
                onClose={logout}
                show={true}
                onSubmit={
                    !props.acceptedValidTac && !props.acceptedValidPs
                        ? acceptTacAndPs
                        : !props.acceptedValidTac
                            ? acceptTac
                            : acceptPrivacyStatement
                }>
                {
                    !props.acceptedValidTac && !props.acceptedValidPs &&
                    <p>Die Bestimmungen bezüglich <strong className="SUDownloadLink" onClick={async () => await handleDownloadTac()}>AGB &#040;Download&#041;</strong> und <strong className="SUDownloadLink" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz &#040;Download&#041;</strong> wurden aktualisiert. Um unsere App weiterhin verwenden zu können, musst du diese akzeptieren. Alternativ kannst du dich ausloggen.</p>
                }
                {
                    !props.acceptedValidTac && props.acceptedValidPs &&
                    <p>Die Bestimmungen bezüglich den <strong className="SUDownloadLink" onClick={async () => await handleDownloadTac()}>AGB's &#040;Download&#041;</strong> wurden aktualisiert. Um unsere App weiterhin verwenden zu können, musst du diese akzeptieren. Alternativ kannst du dich ausloggen.</p>
                }
                {
                    props.acceptedValidTac && !props.acceptedValidPs &&
                    <p>Die Bestimmungen bezüglich des <strong className="SUDownloadLink" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutzes &#040;Download&#041;</strong> wurden aktualisiert. Um unsere App weiterhin verwenden zu können, musst du diese akzeptieren. Alternativ kannst du dich ausloggen.</p>
                }
                <p className={errMsg ? "errmsgCustomerOverview" : "offscreen"}>{errMsg}</p>
            </Modal>
            {
                isCurrentlyProcessing &&
                <div className="saveLoadingSpinnerArea">
                    <LoadingSpinner />
                </div>
            }
            <Idle />
        </div>
    )
}