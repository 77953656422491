//Technology
import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "../../api/axios";
//Components
import FailAlert from "../../components/Modals/FailAlert";
import FormInput from "../../components/Inputs/FormInput";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
import img2 from "../../assets/Auth/Wireframe_Mockup_Login_Registrierung.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";

export default function ForgotPassword(props) {
  const errRef = useRef(); //avoids rerendering
  const userRef = useRef(); //avoids rerendering
  const navigate = useNavigate();

  //navigation routes
  const forgotPassThankYouRoute = "/ForgotPasswordThankYou";

  //api url paths
  const resetPasswort_URL = "/sendresetpasswordLink";

  // Array with input properties
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "name@example.de",
      label: "E-Mail-Adresse",
    },
  ];

  //useStates
  const [errMsg, setErrMsg] = useState("");
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [values, setValues] = useState({
    email: "",
  });

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  // sets an error message
  useEffect(() => {
    setErrMsg("");
  }, [values.email]);

  //request to server and error handling
  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(resetPasswort_URL, JSON.stringify({ email: values.email }), {
        headers: { "Content-Type": "application/json" }
      })
      .then((response) => {
        navigate(forgotPassThankYouRoute);
      })
      .catch((error) => {
        setErrMsg(error?.response?.data?.error);
        errRef.current.focus();
      });
  };

  //changes values of input fields
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleDownloadTac() {
    try {
      await downloadTaC();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    try {
      await downloadPrivacyStatements();
    } catch (error) {
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  function getLinkPart() {
    return (
      <>
        <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
        <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
        <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
      </>
    )
  }

  return (
    <div className="bodyCustomerLogin forgotpw-container">
      {/* container for left side of the customer login site */}
      <div className="containerForLeft">
        {/* container for the heading and paragraph that gets displayed on the left side */}
        <div className="containerForHeadingAndParagraph">
          <h1 className="headingOne">Erstelle deinen Businessplan!</h1>
          <p className="paragraphOne">
            Zu jeder Unternehmensgründung gehört auch ein Businessplan.{" "}
            <br /> Bei uns hast du die Möglichkeit einfach aber effektiv deine Gründungsidee zu verwirklichen!
          </p>
        </div>

        <img src={img2} className="imageOfApp" alt="" />
        <div className="containerForParagraphs">
          {getLinkPart()}
        </div>
      </div>

      {/* contaienr for the right side of the customer login site */}
      <div className="containerForLogin">
        {/* attempt to make the logo clickable -> not finished yet */}

        {/* container for the components of the customer login such as inputs, button etc */}
        <div className="customerLogin">
          {" "}
          <img src={img1} className="logoB3" alt="" />
          <form onSubmit={handleSubmit}>
            <h1 className="headingForgotPassword">Passwort vergessen</h1>

            <p className="pForgotPasswordText">
              Bitte gib deine E-Mail Adresse ein und wir senden
              <br />
              dir per E-Mail einen Link zum Zurücksetzen deines <br />
              Passworts.
            </p>

            {inputs.map((input) => (
              <FormInput
                refs={userRef}
                key={input.id}
                {...input}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>
              {errMsg}
            </p>

            <div className="containerBtnAndRegister">
              <button className="btnAnmelden">E-Mail versenden</button>
              <p className="registerHere">Zurück zur <Link to={props.isEmployee ? '/employeelogin' : '/customerlogin'} className="hoverLink">Anmeldung</Link>
              </p>
            </div>
          </form>
          <div className="customerLoginLinkPartResponsive">
            {getLinkPart()}
          </div>
        </div>
      </div>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
    </div>
  );
}
