//Technology
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Components
import AlertModal from "../Modals/AlertModal";
import DropFileInput from "../FileUpload/DropFile";
import FailAlert from "../Modals/FailAlert";
import LoadingSpinner from "../Loading/LoadingSpinner";
import Modal from "../Modals/Modal";
import SuccessAlert from "../Modals/SuccessAlert";
//fileDownload
var fileDownload = require('js-file-download');

export default function TableTemplatesList() {
    const axiosPrivate = useAxiosPrivate();

    //api url paths
    const alltabletemplates_URL = "/alltabletemplates";
    const uploadtabletemplate_URL = "/uploadtabletemplate";
    const downloadtabletemplate_URL = "/downloadtabletemplate";
    const deletetabletemplate_URL = "/deletetabletemplate";

    //useStates for data
    const [tabletemplateData, setTabletemplateData] = useState([]);
    const [foldedChapters, setFoldedChapters] = useState([]);
    const [foldedSubchapters, setFoldedSubchapters] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    //useStates for loading
    const [isLoading, setIsLoading] = useState(true);
    const [getDataSuccessFull, setGetDataSuccessFull] = useState(false);
    const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);

    //useStates for modals
    const [showUpload, setShowUpload] = useState([]); //for upload and edit
    const [showDelete, setShowDelete] = useState([]);
    const [showSuccessUpload, setShowSuccessUpload] = useState(false);
    const [showSuccessEdit, setShowSuccessEdit] = useState(false);
    const [showSuccessDelete, setShowSuccessDelete] = useState(false);
    const [showErrorDelete, setShowErrorDelete] = useState(false);

    //useStates for success messages from requests
    const [msgShowSuccessUpload, setMsgShowSuccessUpload] = useState("");
    const [msgShowSuccessEdit, setMsgShowSuccessEdit] = useState("");
    const [msgShowSuccessDelete, setMsgShowSuccessDelete] = useState("");

    //useStates for error messages from requests
    const [errMsg, setErrMsg] = useState(""); //for upload and edit
    const [errMsgFetchedTables, setErrMsgFetchedTables] = useState("");//for fetched tables
    const [errMsgDeleteTable, setErrMsgDeleteTables] = useState("");//for delete table
    const [errMsgForDownloadTable, setErrMsgForDownloadTable] = useState("");//for download table

    const onFileChange = async (file) => {
        setSelectedFile(file)
    }

    //fetches tabletemplate data
    const fetchTable = async () => {
        await axiosPrivate
            .get(alltabletemplates_URL)
            .then((response) => {
                setTabletemplateData(response.data);
                setErrMsgFetchedTables("");
                setGetDataSuccessFull(true);
                setIsLoading(false);
                setIsCurrentlyProcessing(false);
            })
            .catch((error) => {
                setErrMsgFetchedTables(error.response.data.error)
                setGetDataSuccessFull(false);
                setIsLoading(false);
                setIsCurrentlyProcessing(false);
            });
    };

    async function uploadTableTemplate(tablestructureid, templatefilename) {
        const formData = new FormData();
        formData.append("file", selectedFile);

        await axiosPrivate({
            method: "POST",
            url: uploadtabletemplate_URL,
            params: { tablestructureid },
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(async (res) => {
                setSelectedFile(null)
                setShowUpload(showUpload.filter((e) => e !== tablestructureid))
                if (templatefilename === "keine Datei vorhanden") {
                    setShowSuccessUpload(true)
                    setMsgShowSuccessUpload(res.data.message);
                    setErrMsg("");
                    setTimeout(() => { setShowSuccessUpload(false) }, 3000);
                } else {
                    setShowSuccessEdit(true);
                    setMsgShowSuccessEdit(res.data.message);
                    setErrMsg("");
                    setTimeout(() => { setShowSuccessEdit(false) }, 3000);
                }
                await fetchTable();
            }).catch((err) => {
                setErrMsg(err.response.data.error)
                setIsCurrentlyProcessing(false);
            });
    }

    async function deleteTableTemplate(tablestructureid) {
        await axiosPrivate({
            method: "DELETE",
            url: deletetabletemplate_URL,
            params: { tablestructureid: tablestructureid }
        })
            .then(async (response) => {
                setShowSuccessDelete(true);
                setMsgShowSuccessDelete(response.data.message);
                setTimeout(() => { setShowSuccessDelete(false) }, 3000)
                setErrMsgDeleteTables("");
                setShowDelete(showDelete.filter((e) => e !== tablestructureid))
                await fetchTable();
            }).catch((error) => {
                setErrMsgDeleteTables(error.response.data.error);
                setIsCurrentlyProcessing(false);
            });
    }

    async function downloadTableTemplate(templatefileid) {
        setIsCurrentlyProcessing(true);
        await axiosPrivate.get(downloadtabletemplate_URL, {
            params: {
                tabletemplateid: templatefileid
            },
            responseType: 'blob'
        }).then((response) => {
            fileDownload(response.data, response.headers['x-file-name']);
            setErrMsgForDownloadTable("")
            setIsCurrentlyProcessing(false);
        }).catch((error) => {
            setErrMsgForDownloadTable(error.response.data.error);
            setShowErrorDelete(true);
            setIsCurrentlyProcessing(false);
            setTimeout(() => { setShowErrorDelete(false) }, 3000)
        });
    }

    async function toggleUploadTable(tablestructureid, templatefilename) {
        if (!selectedFile) {
            setErrMsg("Upload fehlgeschlagen. Es wurde keine Datei ausgewählt.");
        } else {
            setIsCurrentlyProcessing(true);
            await uploadTableTemplate(tablestructureid, templatefilename)
        }
    }

    async function toggleDeleteTableTemplate(tablestructureid) {
        setIsCurrentlyProcessing(true);
        await deleteTableTemplate(tablestructureid);
        setSelectedFile(null);
    }

    async function handleOnClose(tablestructureid) {
        setShowUpload(showUpload.filter((e) => e !== tablestructureid));
        setSelectedFile(null);
        setErrMsg("");
    }

    async function handleOnCloseForDelete(tablestructureid) {
        setShowDelete(showDelete.filter((e) => e !== tablestructureid))
        setErrMsgDeleteTables("")
    }

    const ChapterElement = ({ data }) => {
        return (
            <tbody>
                <tr className="trMainChapterTableTemplates">
                    <td className={foldedChapters.includes(data.chapterstructureid) ? "btnToggleExpand" : "btnToggleExpandActive"}>
                        <button className="buttonForToggleTransparent" onClick={() => toggleChapter(data.chapterstructureid)}></button>
                    </td>
                    <td></td>
                    <td>Kapitel: {data.chaptertitle}</td>
                    <td></td>
                </tr>
                {
                    data.subchapters && (data.subchapters)
                        .map((elem, index) => {
                            return <SubchapterElement
                                rowStyle={{ display: foldedChapters.includes(data.chapterstructureid) ? "none" : "table-row" }}
                                key={`${index}`}
                                data={elem}
                            />
                        })
                }
            </tbody>
        )
    }

    const SubchapterElement = ({ data, rowStyle }) => {
        let arr = [];
        arr.push(< tr className="trSubrowTableTemplates" key={""} style={rowStyle}>
            <td></td>
            <td className={foldedSubchapters.includes(data.subchapterstructureid) ? "btnToggleExpand" : "btnToggleExpandActive"}>
                <button className="buttonForToggleTransparent" onClick={() => toggleSubchapter(data.subchapterstructureid)}></button>
            </td>
            <td>Unterkapitel: {data.subchaptertitle}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr >);
        if (data.tables && rowStyle.display !== "none") {
            for (const table of data.tables) {
                arr.push(<TableElement
                    rowStyle={{
                        display: foldedSubchapters.includes(data.subchapterstructureid)
                            ? "none" : "table-row"
                    }}
                    key={`${table.tablestructureid}`}
                    data={table}
                />);
            }
        }
        return arr;
    }

    const TableElement = ({ data, rowStyle }) => {
        return (
            (data.templatefilename !== "keine Datei vorhanden")
                ? <tr className="trSubrowSubChaptersTableTemplates" style={rowStyle}>
                    <td></td>
                    <td></td>
                    <td>{data.tablestructuretitle}</td>
                    <td className={data.templatefilename === "keine Datei vorhanden" ? "trStatusNoDataTableTemplates" : "trStatusDataFoundTableTemplates"}>{data.templatefilename}</td>
                    <td className="tdForIcons">
                        <div className="tdForDownloadButtonTableTemplates"
                            onClick={() => downloadTableTemplate(data.templatefileid)}>
                        </div>
                    </td>
                    <td className="tdForIcons">
                        <div className="tdForEditButtonTableTemplates"
                            onClick={() => setShowUpload([...showUpload, data.tablestructureid,])}>
                        </div>
                        <Modal
                            title="Tabellenvorlage ändern? "
                            name="Bearbeiten"
                            button="Abbrechen"
                            onClose={() =>
                                handleOnClose(data.tablestructureid)
                            }
                            show={showUpload.includes(data.tablestructureid)}
                            onSubmit={() => toggleUploadTable(data.tablestructureid, data.templatefilename)}
                        >
                            <p>
                                Die aktuelle Tabellenvorlage <b>{data.templatefilename}</b> wird nicht aus der Datenbank gelöscht.
                                Neu erstellte Businesspläne werden nach dem Ändern mit der neuen Tabellenvorlage für diese Tabelle erstellt.
                                Bereits erstellte Businesspläne enthalten für diese Tabelle weiterhin die zu dem Zeitpunkt der Erstellung hinterlegte Tabellenvorlage.
                            </p>
                            <DropFileInput
                                onFileChange={(files) => onFileChange(files)} filename={selectedFile ? selectedFile.name : null}
                            />
                            <p className={errMsg ? "errMsgTableTemplates" : "offscreen "}>{errMsg}</p>
                        </Modal>
                    </td>
                    <td className="tdForIcons">
                        <div className="tdForDeleteButtonTableTemplates"
                            onClick={() => setShowDelete([...showDelete, data.tablestructureid,])}></div>
                        <AlertModal
                            title="Tabellenvorlage aus der Tabelle entfernen?"
                            name="Ja, Datei löschen"
                            button="Abbrechen"
                            onClose={() => handleOnCloseForDelete(data.tablestructureid)}
                            show={showDelete.includes(data.tablestructureid)}
                            onSubmit={() => toggleDeleteTableTemplate(data.tablestructureid)}
                        >
                            <p>
                                Die aktuelle Tabellenvorlage <b>{data.templatefilename}</b> wird nicht aus der Datenbank gelöscht.
                                Neu erstellte Businesspläne werden nach dem Entfernen allerdings
                                ohne Tabellenvorlage für diese Tabelle erstellt.
                                <br></br>
                                Bereits erstellte Businesspläne enthalten für diese Tabelle
                                weiterhin die zu dem Zeitpunkt der Erstellung hinterlegte Tabellenvorlage.
                            </p>
                            <p className={errMsgDeleteTable ? "errMsgTableTemplates" : "offscreen"}>{errMsgDeleteTable}</p>
                        </AlertModal>
                    </td>
                </tr>
                : <tr className="trSubrowSubChaptersTableTemplates" style={rowStyle}>
                    <td></td>
                    <td></td>
                    <td>{data.tablestructuretitle}</td>
                    <td className={data.templatefilename === "keine Datei vorhanden"
                        ? "trStatusNoDataTableTemplates" : "trStatusDataFoundTableTemplates"}>
                        {data.templatefilename}</td>
                    <td ></td>
                    <td></td>
                    <td className="tdForIcons">
                        <div className="tdIconForUploadTableTemplates"
                            onClick={() => setShowUpload([...showUpload, data.tablestructureid,])}>
                        </div>
                        <Modal
                            title="Tabellenvorlage hinterlegen?"
                            name="Hochladen"
                            button="Abbrechen"
                            onClose={() => handleOnClose(data.tablestructureid)}
                            show={showUpload.includes(data.tablestructureid)}
                            onSubmit={() => toggleUploadTable(data.tablestructureid, data.templatefilename)}
                        >
                            <p>
                                Nach dem Hinzufügen werden neu erstellte Businesspläne mit der hinterlegten
                                Tabellenvorlage erstellt. </p>
                            <DropFileInput
                                onFileChange={(files) => onFileChange(files)} filename={selectedFile ? selectedFile.name : null}
                            />
                            <p className={errMsg ? "errMsgTableTemplates" : "offscreen "}>{errMsg}</p>
                        </Modal>
                    </td>
                </tr >
        )
    }

    const toggleChapter = (chapterid) => {
        setFoldedChapters((foldedChapters) => {
            if (foldedChapters.includes(chapterid)) {
                return foldedChapters.filter((c) => c !== chapterid);
            } else {
                return [...foldedChapters, chapterid];
            }
        });
    };

    const toggleSubchapter = (subchapterid) => {
        setFoldedSubchapters((foldedSubChapters) => {
            if (foldedSubchapters.includes(subchapterid)) {
                return foldedSubchapters.filter((s) => s !== subchapterid);
            } else {
                return [...foldedSubchapters, subchapterid];
            }
        });
    };

    useEffect(() => {
        fetchTable();
    }, []);

    return (
        <div>
            <div className="divForTableTemplates">
                {
                    isLoading &&
                    <div className="loadingSpinnerDivCustomerOverview">
                        <LoadingSpinner />
                    </div>
                }
                {
                    !isLoading && getDataSuccessFull &&
                    <table className="tableTableTemplates">
                        <thead className="theadTableTemplates">
                            <tr className="trHeadingTableTemplates">
                                <th className="thAccordionButton"></th>
                                <th className="thAccordionButton"></th>
                                <th className="thTable">Tabelle</th>
                                <th className="thFilename">Dateiname</th>
                                <th ></th>
                            </tr>
                        </thead>
                        {tabletemplateData.chapters &&
                            (tabletemplateData.chapters)
                                .map((elem, index) => {
                                    return <ChapterElement
                                        key={`${index}`}
                                        data={elem}
                                    />
                                })}
                    </table>
                }
                {
                    !isLoading && isCurrentlyProcessing &&
                    <div className="saveLoadingSpinnerArea">
                        <LoadingSpinner />
                    </div>
                }
                {
                    !isLoading && !getDataSuccessFull &&
                    <div className="loadingSpinnerDivCustomerOverview">
                        <p className={errMsgFetchedTables ? "errMsgTableTemplates" : "offscreen"}>{errMsgFetchedTables}</p>
                    </div>
                }
            </div>
            <SuccessAlert show={showSuccessUpload} onClose={() => { setShowSuccessUpload(false); setMsgShowSuccessUpload(""); }}><p className="rspMessageSuccess">{msgShowSuccessUpload}</p></SuccessAlert>
            <SuccessAlert show={showSuccessEdit} onClose={() => { setShowSuccessEdit(false); setMsgShowSuccessEdit(""); }}>{<p className="rspMessageSuccess">{msgShowSuccessEdit}</p>}</SuccessAlert>
            <SuccessAlert show={showSuccessDelete} onClose={() => { setShowSuccessDelete(false); setMsgShowSuccessDelete(""); }}>{<p className="rspMessageSuccess">{msgShowSuccessDelete}</p>}</SuccessAlert>
            <FailAlert show={showErrorDelete} onClose={() => setShowErrorDelete(false)}>{<p className="rspMessageError">{errMsgForDownloadTable}</p>}</FailAlert>
        </div>
    );
}