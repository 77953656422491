import React from "react";

/*This modal type is used to display dialogs with destructive or dangerous actions
 * e.g. deleting data or banning a user
 */
const AlertModal = props => {
    if (!props.show) {
        return null
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="alert-modal-title">{props.title}</h2>
                    <div className="modal-close-icon" alt="close" onClick={props.onClose}>
                    </div>
                </div>
                <div className="modal-divider"> </div>
                <div className="modal-body">{props.children}</div>
                <div className="modal-divider"> </div>
                <div className="modal-footer">
                    <button className="modal-close-btn" onClick={props.onClose}>{props.button}</button>
                    {/* need function to check submit based on page and request*/}
                    <button className="alert-modal-submit-btn" onClick={props.onSubmit}>{props.name}</button>
                </div>
            </div>
        </div>
    )
}

export default AlertModal