//Technology
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import axios from "../../api/axios";
//Components
import FormInput from "../../components/Inputs/FormInput";
import FailAlert from "../../components/Modals/FailAlert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
//Assets
import img1 from "../../assets/Logos/B3-Logo_300dpi 1.svg";
//Functions
import { downloadTaC, downloadPrivacyStatements } from "../../functions/DownloadFunctions";
import openLegalDisclosures from "../../functions/OpenLegalDisclosures";

export default function ForgotPasswordReset() {
  const errRef = useRef(); //avoids rerendering
  const userRef = useRef(); //avoids rerendering
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentlyProcessing, setIsCurrentlyProcessing] = useState(false);


  //api url paths
  const resetPassword_URL = "/resetpassword";

  // Array with input properties
  const inputs = [
    {
      id: 1,
      name: "password",
      type: "password",
      label: "Neues Passwort",
    },

    {
      id: 2,
      name: "passwordRepeat",
      type: "password",
      label: "Neues Passwort wiederholen",
    },
  ];

  //useStates
  const [errMsg, setErrMsg] = useState("");
  const [failAlertErrorMessage, setFailAlertErrorMessage] = useState("");
  const [failAlertVisible, setFailAlertVisible] = useState(false);
  const [rspMsg, setRspMsg] = useState("");
  const [values, setValues] = useState({
    password: "",
    passwordRepeat: "",
  });

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  // sets an error message
  useEffect(() => {
    setErrMsg("");
  }, []);

  //request to server and error handling
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    if (values.password === values.passwordRepeat) {
      setIsLoading(true);
      await axios
        .post(
          resetPassword_URL,
          JSON.stringify({ password: values.password, token: param.token }),
          {
            headers: { "Content-Type": "application/json" }
          }
        )
        .then((response) => {
          navigate(from, { replace: true });
          setIsLoading(false);
          setValues({
            password: "",
            passwordRepeat: ""
          })
        })
        .catch((error) => {
          setErrMsg(error?.response?.data?.error);
          setIsLoading(false);
          errRef.current.focus();
        });
    } else {
      setRspMsg("Passwörter stimmen nicht überein");
    }
  };

  //changes values of input fields
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleDownloadTac() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadTaC();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  async function handleDownloadPrivacyStatement() {
    setIsCurrentlyProcessing(true);
    try {
      await downloadPrivacyStatements();
      setIsCurrentlyProcessing(false);
    } catch (error) {
      setIsCurrentlyProcessing(false);
      setFailAlertErrorMessage(error.message);
      setFailAlertVisible(true);
      setTimeout(() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }, 3000)
    }
  }

  return (
    <div className="bodyForgotPasswordReset">
      <header className="headerForgotPasswordReset">
        <img src={img1} className="FPRlogoB3" alt="" />
      </header>
      <div className="FPRcontentContainer">
        <div>
          <div className="FPRpositionElements">
            <h1 className="FPRheadingTwo">Passwort zurücksetzen</h1>
          </div>
          <p className="pResetPassword">Lege bitte ein neues Passwort für dein Konto fest.</p>
          <div className="FPRPositionInputs">
          {inputs.map((input) => (
            <FormInput
              refs={userRef}
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          </div>
          <div className="FPRmessages">
          <p ref={errRef} className={errMsg ? "FPRerrmsgReset" : "FPRrspmsgReset"}>
              {errMsg || rspMsg}
            </p>
          </div>
          <div className="FPRcontainerBtnAndBackToLogin">
            <form onSubmit={handleSubmit}>
              <div className="FPRpositionLoadingSpinner">
                <button className="btnResetPassword">{isLoading ? <LoadingSpinner /> : "Neues Passwort setzen"}</button>
              </div>
            </form>
            <p className="registerHere">Zurück zur <Link to="/customerlogin" className="hoverLink">Anmeldung</Link></p>
          </div>
        </div>
      </div>
      <footer className="footerForgotPasswordReset">
        <div className="FPRlinkArea">
          <div className="FPRfooterItem">
            <p className="pAGB" onClick={async () => await handleDownloadTac()}>AGB</p>
          </div>
          <div className="AALfooterItem">
            <p className="pDatenschutz" onClick={async () => await handleDownloadPrivacyStatement()}>Datenschutz</p>
          </div>
          <div className="AALfooterItem">
            <p className="pImpressum" onClick={openLegalDisclosures}>Impressum</p>
          </div>
        </div>
      </footer>
      <FailAlert show={failAlertVisible} onClose={() => { setFailAlertVisible(false); setFailAlertErrorMessage(""); }}>{<p className="rspMessageError">{failAlertErrorMessage}</p>}</FailAlert>
      {
        isCurrentlyProcessing &&
        <div className="saveLoadingSpinnerArea">
          <LoadingSpinner />
        </div>
      }
    </div>
  );
}
